<div *ngIf="userdetails" class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            <span *ngIf="endshift === 'Yes'">End</span>
            <span *ngIf="endshift === 'No'">Start</span>
            Shift</div>
        <button
            mat-icon-button
            (click)="saveAndClose()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <div *ngIf="userdetails && userdetails.ro_data"
        class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto"
        >
        <!-- <mat-form-field class="flex-auto gt-xs:pl-3 mr-2">
            <mat-label>CC Code</mat-label>
                <mat-select name="ro_code" id="ro_code"  [(ngModel)]="ro_code" required  placeholder="Choose CC Code" (ngModelChange)="fetchdata()">
                    <mat-option [value]="roo" *ngFor="let roo of userdetails.ro_data;">{{roo}}</mat-option>
                </mat-select>
        </mat-form-field> -->
        <mat-form-field class="flex-auto gt-xs:pl-3 mr-2" *ngIf="endshift === 'No'">
            <mat-label>Shift</mat-label>
                <mat-select name="selectedShift" id="selectedShift"  [(ngModel)]="selectedShift" required  placeholder="Choose shift">
                    <mat-option [value]="sft" *ngFor="let sft of validshifts;">{{sft.shift_name}}</mat-option>
                </mat-select>
        </mat-form-field>
        <mat-form-field class="flex-auto gt-xs:pl-3 mr-2" *ngIf="endshift === 'No'">
            <mat-label>DUs</mat-label>
                <mat-select matNativeControl name="selectedDu" id="selectedDu" [(ngModel)]="selectedDu" required  placeholder="Choose DUs" multiple (ngModelChange)="filternozzle()">
                    <mat-option [value]="dun" *ngFor="let dun of dulist;">{{dun.du_id}}</mat-option>
                </mat-select>
        </mat-form-field>
        <mat-form-field class="flex-auto gt-xs:pl-3 mr-2" *ngIf="nozzlelist && nozzlelist.length>0 && endshift === 'No'">
            <mat-label>Nozzles</mat-label>
                <mat-select  name="selectedNozzle" [(ngModel)]="selectedNozzle" required  placeholder="Choose Nozzles" multiple>
                    <mat-option [value]="dun" *ngFor="let dun of nozzlelist;"><span *ngIf="dun && dun.du_master_ref">DU: {{dun.du_master_ref.du_id}} - {{dun.nozzle_id}}</span></mat-option>
                </mat-select>
        </mat-form-field>
        <div *ngIf="endshift === 'Yes'" class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
                    <div class="mb-4">
                        <mat-label class="font-bold mr-4">Logout Now</mat-label>
                        <mat-button-toggle-group [(ngModel)]="logout" name="logout"
                        aria-label="Toggle response yes or no">
                        <mat-button-toggle value="Yes" [ngClass]="{'bg-primary text-white': logout === 'Yes','bg-gray-100 text-white': logout !== 'Yes'}">
                          Yes
                        </mat-button-toggle>
                        <mat-button-toggle value="No" [ngClass]="{'bg-primary text-white': logout === 'No', 'bg-gray-100 text-white': logout !== 'No'}">
                          No
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                    <mat-form-field class="flex-auto gt-xs:pl-3 mr-2">
                        <mat-label>CC Code</mat-label>
                            <input matInput name="ro_code" id="ro_code" [disabled]='true' [(ngModel)]="ro_code">
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 mr-2">
                        <mat-label>DU</mat-label>
                            <input matInput name="selectedDU" id="selectedDU" [disabled]='true' [(ngModel)]="postData.selectedDU">
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 mr-2">
                        <mat-label>Nozzle</mat-label>
                            <input matInput name="selectedNozzle" id="selectedNozzle" [disabled]='true' [(ngModel)]="postData.selectedNozzle">
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 mr-2">
                        <mat-label>Shift Name</mat-label>
                            <input matInput name="shift_name" id="shift_name" [disabled]='true' [(ngModel)]="selectedShift.shift_name">
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 mr-2">
                        <mat-label>Shift Start Time</mat-label>
                            <input matInput name="shift_open_time" id="shift_open_time" [disabled]='true' [(ngModel)]="selectedShift.shift_open_time">
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 mr-2">
                        <mat-label>Shift end Time</mat-label>
                            <input matInput name="shift_close_time" id="shift_close_time" [disabled]='true' [(ngModel)]="selectedShift.shift_close_time">
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 mr-2">
                        <mat-label>Login Time</mat-label>
                            <input matInput name="login_time" id="login_time" [disabled]='true' [(ngModel)]="postData.login_time">
                    </mat-form-field>
                </div>

            <div class="flex items-center mt-4 sm:mt-0">
                <button
                    class="order-first sm:order-last"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="send()">
                    <span *ngIf="endshift == 'No'">Save</span>
                    <span *ngIf="endshift == 'Yes'">Ok</span>
                </button>
            </div>
        </div>
    </div>

<!-- <div class="flex flex-col w-full max-h-screen -m-6">
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
        Details</div>
        <button (click)="Close()" mat-icon-button [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div *ngIf="postData" class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
        <form class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <mat-form-field class="w-full" *ngFor="let field of postData | keyvalue">
                <mat-label>{{ field.key }}</mat-label>
                <input class="ml-3"  matInput [name]="field.key.toLowerCase()" [id]="field.key.toLowerCase()"
                    [(ngModel)]="postData[field.key]">
            </mat-form-field>
        </form>

    </div>
</div> -->
<div class="flex flex-col max-w-240 md:min-w-200 -m-6" *ngIf="usersData && usersData.length >0">
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            Details</div>
        <button (click)="Close()" mat-icon-button [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div *ngIf="usersData && usersData.length >0">
        <div *ngIf="postData" class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
            <form class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <mat-form-field class="w-full" *ngFor="let field of postData | keyvalue">
                    <mat-label>{{ field.key }}</mat-label>
                    <input class="ml-3"  matInput [name]="field.key.toLowerCase()" [id]="field.key.toLowerCase()"
                        [(ngModel)]="postData[field.key]">
                </mat-form-field>
            </form>
        </div>
    </div>
</div>
<div class="flex flex-col max-w-240 md:min-w-200 -m-6" *ngIf="auditData && auditData.length>0">
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            Details</div>
        <button (click)="Close()" mat-icon-button [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div *ngIf="auditData && auditData.length>0">
        <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
            <div class="w-full max-w-3xl">
                <ng-container>
                    <div class="mt-2">
                        <ol>
                            <div class="grid grid-cols-1" *ngIf="selectedlogshow">
                                <ng-container  *ngFor="let item of selectedlogshow | keyvalue">
                                <div class="p-3" *ngIf="item.value && item.value.length>0 && item.key === 'description'">
                                    <ng-container >
                            <mat-form-field class="w-full " >
                                <mat-label>{{headerformat(item.key) | titlecase}}</mat-label>
                               
                                    <textarea *ngIf="item.key === 'description'"
                                    matInput [name]="'item.key'" [id]="'item.key'"  readonly
                                    [value]="valueformat(item.value, item.key)"></textarea>
                            </mat-form-field>
                        </ng-container>
                    
                        </div>
                    </ng-container>
                            </div> 
                            <div class="grid grid-cols-2 md:grid-cols-3">
                                <ng-container *ngFor="let item of selectedlogshow | keyvalue">
                                <div  class="p-3" *ngIf="item.key !== 'description' ">
                                    <ng-container >
                            <mat-form-field class="w-full" >
                                <mat-label>{{headerformat(item.key) | titlecase}}</mat-label>
                                <input
                                    matInput [name]="'item.key'" [id]="'item.key'"  readonly
                                    [value]="valueformat(item.value, item.key)">
                            </mat-form-field>
                        </ng-container>
                        </div>
                        </ng-container>
                            </div>
                            <div class="grid grid-cols-2 md:grid-cols-3" *ngIf="selectedmetadata">
                                <div  class="p-3" *ngFor="let item of selectedmetadata | keyvalue">
                            <mat-form-field class="w-full " >
                                <mat-label>{{headerformat(item.key) | titlecase}}</mat-label>
                                <input
                                    matInput [name]="'item.key'" [id]="'item.key'"  readonly
                                    [value]="valueformat(item.value, item.key)">
                            </mat-form-field>
                        </div>
                            </div>  
                        </ol>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>
</div>
<div class="flex flex-col max-w-240 md:min-w-200 -m-6" *ngIf="shiftData && shiftData.length >0">
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            Details</div>
        <button (click)="Close()" mat-icon-button [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div *ngIf="shiftData && shiftData.length >0">
        <div *ngIf="selectedProduct" class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
            <form class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <mat-form-field class="w-full" *ngIf="selectedProduct.shift_type == 'RO'">
                    <mat-label>CC Code</mat-label>
                    <input class="ml-3"  matInput name="ro_code" id="ro_code" [disabled]="true"
                        [(ngModel)]="selectedProduct.ro_master_ref.ro_code">
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label>Shift Name</mat-label>
                    <input class="ml-3"  [disabled]="true" matInput name="shift_name" id="shift_name"
                        [(ngModel)]="selectedProduct.shift_name">
                </mat-form-field>
                <div class="flex flex-col">
                    <span class="font-semibold mb-2">Shift status</span>
                    <mat-slide-toggle [disabled]="true" name="shift_status" [(ngModel)]="selectedProduct.shift_status"
                        [color]="'primary'">
                        {{selectedProduct.shift_status === true ? 'Active' : 'Inactive'}}
                    </mat-slide-toggle>
                </div>
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Shift Start Time</mat-label>
                    <input class="ml-3"  matInput [format]="24" [disabled]="true" name="shift_open_time" [ngxMatTimepicker]="picker"
                        [(ngModel)]="selectedProduct.shift_open_time" />
                    <mat-icon matSuffix (click)="picker.open()">
                    </mat-icon>
                    <ngx-mat-timepicker [appendToInput]="true" #picker></ngx-mat-timepicker>
                    <mat-icon matSuffix (click)="picker.open()">schedule</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Shift End Time</mat-label>
                    <input class="ml-3"  matInput [format]="24" [disabled]="true" name="shift_close_time" [ngxMatTimepicker]="pickerc"
                        [(ngModel)]="selectedProduct.shift_close_time" />
                    <mat-icon matSuffix (click)="pickerc.open()">
                    </mat-icon>
                    <ngx-mat-timepicker [appendToInput]="true" #pickerc></ngx-mat-timepicker>
                    <mat-icon matSuffix (click)="pickerc.open()">schedule</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </div>
</div>
<div class="flex flex-col max-w-240 md:min-w-200 -m-6" *ngIf="alertDeatailsdata && alertDeatailsdata.length >0">
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            Details</div>
        <button (click)="Close()" mat-icon-button [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div *ngIf="alertDeatailsdata && alertDeatailsdata.length >0">
        <ng-container *ngIf="selectedlogDetails">
            <ng-container>
                <div class="mt-2 p-4">
                    <ol>
                        <div class="grid grid-cols-1" *ngIf="selectedlogDetailsda">
                            <div class="p-3" *ngFor="let item of selectedlogDetailsda | keyvalue">
                                <ng-container
                                    *ngIf="item.value && item.value.length>0 && item.key === 'description' || item.key === 'alert_type'">
                                    <mat-form-field class="w-full ">
                                        <mat-label>{{headerformat(item.key) |
                                            titlecase}}</mat-label>
                                        <input class="ml-3" class="ml-3"
                                            *ngIf="item.key === 'alert_type'" matInput
                                            [name]="'item.key'" [id]="'item.key'" readonly
                                            [value]="valueformat(item.value, item.key)">
                                        <textarea *ngIf="item.key === 'description'" matInput
                                            [name]="'item.key'" [id]="'item.key'" readonly
                                            [value]="valueformat(item.value, item.key)"></textarea>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                        <div class="grid grid-cols-3" *ngIf="selectedlogDetails">
                            <div class="p-3" *ngFor="let item of selectedlogDetails | keyvalue">
                                <ng-container *ngIf="item.value && item.value.length>0">
                                    <mat-form-field class="w-full ">
                                        <mat-label>{{headerformat(item.key) |
                                            titlecase}}</mat-label>
                                        <input class="ml-3" matInput [name]="'item.key'"
                                            [id]="'item.key'" readonly
                                            [value]="valueformat(item.value, item.key)">

                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                    </ol>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<div class="" *ngIf="transData && transData.length>0" class="h-160 w-full overflow-auto print:overflow-visible">
    <div class="flex flex-0 items-center justify-between">
        <!-- <div class="text-lg font-medium">
           Transaction Details</div> -->
           <div class="spacer"></div>
        <button (click)="Close()" mat-icon-button [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div class="inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>

        <div class="inline-block pl-6 pr-6 text-left print:p-0">

            <div
                class="pl-10 pr-10 pt-2 rounded-2xl shadow print:w-auto print:rounded-none print:shadow-none print:bg-transparent">

                <div class="flex items-start justify-between">
                    <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                        <div class="text-2xl tracking-tight text-secondary">Transaction</div>
                        <div class="text-2xl">#{{transacttionData.fcc_txn_id}}</div>
                        <div class="text-xl tracking-tight text-secondary">PRODUCT</div>
                        <div class="text-md">{{transacttionData.product_code}}</div>
                        <div class="text-md tracking-tight text-secondary">START DATE & TIME</div>
                        <div class="text-md">{{processdate(transacttionData.transaction_start_datetime) }}</div>
                        <div class="text-md tracking-tight text-secondary">END DATE & TIME</div>
                        <div class="text-md">{{processdate(transacttionData.transaction_end_datetime) }}</div>
                        <div class="text-md tracking-tight text-secondary">TOTAL AMOUNT</div>
                        <div class="text-md flex flex-row">{{transacttionData.transaction_amount }}<p
                                class="text-secondary">/-</p>
                        </div>
                        <div class="text-md tracking-tight text-secondary">TOTAL VOLUME</div>
                        <div class="text-md flex flex-row">{{transacttionData.transaction_volume}}
                            <p *ngIf="transacttionData.unit_of_measurement" class="text-secondary">
                                {{transacttionData.unit_of_measurement}}</p>
                            <p *ngIf="!transacttionData.unit_of_measurement" class="text-secondary">L</p>
                        </div>
                        <div class="text-md tracking-tight text-secondary">PAYMENT MODE</div>
                        <div class="text-md">{{transacttionData.method_of_payment}}</div>
                    </div>
                </div>

                <div class="text-md mt-8">
                    <div class="text-xl text-md flex flex-row">{{transacttionData.ro_code}}: <p
                            *ngIf="transacttionData.rodetails"> {{transacttionData.rodetails.ro_name}}</p>
                    </div>
                    <div *ngIf="transacttionData.rodetails">{{transacttionData.rodetails.address}}</div>
                    <div *ngIf="transacttionData.rodetails">{{transacttionData.rodetails.pincode}}</div>
                    <div *ngIf="transacttionData.rodetails">{{transacttionData.rodetails.dealer_name}}:
                        {{transacttionData.rodetails.dealer_phone_number}}</div>
                </div>

                <div class="grid grid-cols-16 gap-x-1 mt-5">
                    <div class="col-span-6 self-center text-md text-md">CONFIGURATION</div>
                    <div class="col-span-4 self-center text-md text-right">VALUE</div>
                    <div class="col-span-10 my-1 border-b"></div>
                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">END TOTALIZER AMOUNT
                    </div>
                    <div class="col-span-4 self-center text-right">{{transacttionData.end_totalizer_amount}}</div>
                    <div class="col-span-10 my-1 border-b"></div>
                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">END TOTALIZER VOLUME
                    </div>
                    <div class="col-span-4 self-center text-right">{{transacttionData.end_totalizer_volume}}
                    </div>

                    <div class="col-span-10 my-1 border-b"></div>

                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">PRESET TYPE</div>
                    <div class="col-span-4 self-center text-right">{{transacttionData.preset_type}}</div>

                    <div class="col-span-10 my-1 border-b"></div>

                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">PRESET AMOUNT</div>
                    <div class="col-span-4 self-center text-right">{{transacttionData.preset_amt}}</div>

                    <div class="col-span-10 my-1 border-b"></div>

                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">PRESET QUANTITY</div>
                    <div class="col-span-4 self-center text-right">{{transacttionData.preset_qty}}</div>

                    <div class="col-span-10 my-1 border-b"></div>

                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">RATE PER UNIT</div>
                    <div class="col-span-4 self-center text-right">{{transacttionData.rate_per_unit}}</div>

                    <div class="col-span-10 my-1 border-b"></div>

                    <div class="col-span-10 mt-4"></div>

                    <div class="col-span-6 mb-4 self-center text-md tracking-tight">DEVICE DETAILS</div>
                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">NOZZLE ID</div>
                    <div class="col-span-4 text-right text-lg">{{transacttionData.nozzle_id}}</div>


                    <div class="col-span-10 my-1 border-b"></div>


                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">NOZZLE DU PROTOCOL ID
                    </div>
                    <div class="col-span-4 text-right text-lg">{{transacttionData.nozzle_id_du_protocol}}</div>

                    <div class="col-span-10 my-1 border-b"></div>
                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">PUMP DU PROTOCOL ID
                    </div>
                    <div class="col-span-4 text-right text-lg">{{transacttionData.pump_id_du_protocol}}</div>

                    <div class="col-span-10 my-1 border-b"></div>

                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">TANK ID</div>
                    <div class="col-span-4 text-right text-lg">{{transacttionData.tank_id}}</div>

                    <div class="col-span-10 my-1 border-b"></div>

                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">DU ID</div>
                    <div class="col-span-4 text-right text-lg">{{transacttionData.du_id}}</div>

                    <div class="col-span-10 my-1 border-b"></div>
                    <div class="col-span-6 self-center text-md tracking-tight text-secondary">MACHINE ID</div>
                    <div class="col-span-4 text-right text-lg">{{transacttionData.machine_id}}</div>

                    <div class="col-span-10 my-1 border-b"></div>

                </div>

            </div>

        </div>

    </div>
</div>

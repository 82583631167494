
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseRequestService } from 'app/_services/base.service';
import { ModalService } from 'app/_services/modal.service';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MyToastrService } from 'app/_services/toastr.service';
import { Data } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-matshared',
  templateUrl: './matshared.component.html',
  styleUrls: ['./matshared.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatsharedComponent {
  postData: any = {};
  approvalValue: any;
  rejectionReason: any;
  selectedProduct: any;
  transData = []
  transacttionData: any
  ro_code: string;
  usersData: any = []
  auditData: any = []
  shiftData: any = []
  selectedlog: any = {}
  selectedlogshow: any = {};
  selectedmetadata: any = {};
  @Input() tank;
  alertDeatailsdata:any=[]
  selectedlogDetailsda: any = {}
  selectedlogDetails: any = {}
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public matDialogRef: MatDialogRef<MatsharedComponent>,
    private _formBuilder: UntypedFormBuilder,
    public modalService: ModalService,
    private api: BaseRequestService,
    public toast: MyToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseConfirmationService: FuseConfirmationService,
  ) {
  }

  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('user'))
    this.ro_code = localStorage.getItem('loggedinro')
    this.postData = this.data;
    console.log('postData', this.postData);
    if (window.location.href.includes('users')) {
      this.usersData = Object.keys(this.postData);
    } else if (window.location.href.includes('audit-log/audit')) {
      this.auditData = Object.keys(this.postData)
      this.selectedlog = this.postData
      Object.keys(this.selectedlog).forEach((k, i) => {
        if (this.selectedlog[k] && this.selectedlog[k] != null && this.selectedlog[k] !== undefined && this.selectedlog[k] != '' && k !== 'metadata' && k !== '_id' && k !== 'tid') {
          if (k !== 'c' && k !== 'u') {
            this.selectedlogshow[k] = this.selectedlog[k]
          }
          this.selectedlogshow['created'] = (this.selectedlog['c'].split('.')[0]).replace('T', " ")
          this.selectedlogshow['updated'] = (this.selectedlog['u'].split('.')[0]).replace('T', " ")
        }
      })
      console.log('selectedlogshow', this.selectedlogshow);
      this.selectedmetadata = {};
      const meta = this.selectedlog.metadata
      Object.keys(meta).forEach((k, i) => {
        if (meta[k] && meta[k] != null && meta[k] !== undefined && meta[k] != '' && k !== 'metadata' && k !== '_id' && k !== 'tid') {
          if (k !== 'c' && k !== 'u') {
            this.selectedmetadata[k] = meta[k]
          }
        }
      })
      console.log('selectedmetadata', this.selectedmetadata);

    } else if (window.location.href.includes('shifts')) {
      this.shiftData = Object.keys(this.postData);
      this.selectedProduct = JSON.parse(JSON.stringify(this.postData))
      if (this.selectedProduct.shift_status === 'Active') {
        this.selectedProduct.shift_status = true
      }
      else {
        this.selectedProduct.shift_status = false
      }
    } else if (window.location.href.includes('du-transactions')) {
      this.fetchro(this.postData.ro_code);
      this.transData = Object.keys(this.postData);
      this.transacttionData = this.postData;
      this._changeDetectorRef.markForCheck();
    } else  if (window.location.href.includes('alerts')) {
      this.alertDeatailsdata =Object.keys(this.postData);
      this.selectedlog = this.postData
      this.selectedlogDetails = {}
      Object.keys(this.selectedlog).forEach((k, i) => {
        if (this.selectedlog[k] && this.selectedlog[k] != null && this.selectedlog[k] !== undefined && this.selectedlog[k] != '' && k !== 'metadata' && k !== '_id' && k !== 'tid') {
          if (k !== 'c' && k !== 'u' && k !== 'alert_start_datetime' && typeof (this.selectedlog[k]) !== 'object' && this.selectedlog[k].length > 0 && k !== 'description' && k !== 'alert_type') {
            this.selectedlogDetails[k] = this.selectedlog[k]
          }
          if (typeof (this.selectedlog[k]) === 'object') {
            Object.keys(this.selectedlog[k]).forEach((d, ind) => {
              var tempkey = `${k}->${d}`
              if (this.selectedlog[k][d] && this.selectedlog[k][d].length > 0) {
                this.selectedlogDetails[tempkey] = this.selectedlog[k][d]
              }
            })
          }
          // this.selectedlogDetails['created'] = (this.selectedlog['c'].split('.')[0]).replace('T', " ")
          // this.selectedlogDetails['updated'] = (this.selectedlog['u'].split('.')[0]).replace('T', " ")
          if (this.selectedlog['alert_start_datetime'] && this.selectedlog['alert_start_datetime'].length > 0) {
            this.selectedlogDetails['Alert created Time'] = (this.selectedlog['alert_start_datetime'].split('.')[0]).replace('T', " ")
          }
          // if (this.selectedlog['alert_closeTime'] && this.selectedlog['alert_closeTime'].length > 0) {
          //   this.selectedlogDetails['Alert Closed Time'] = (this.selectedlog['alert_closeTime'].split('.')[0]).replace('T', " ")
          // }

        }
        console.log('selectedlogDetails',this.selectedlogDetails);
        if (k === 'description' || k === 'alert_type') {
          this.selectedlogDetailsda[k] = this.selectedlog[k]
        }
      })
    }


    // if (!this.postData.water_volume_offset) {
    //   this.postData.water_volume_offset = '0'
    // }
    // if (!this.postData.fuel_volume_offset) {
    //   this.postData.fuel_volume_offset = '0'
    // }
    // if (!this.postData.density_offset) {
    //   this.postData.density_offset = '0'
    // }


  }
  fetchro(rocode) {
    let q = { "ro_code": String(rocode) }
    this.api.doRequest(`/api/ro_onboard/pull_romaster`, 'post', q).subscribe((result: any) => {
      if (result && result.data && result.data.length > 0 && result.status == 'success') {
        this.transacttionData.rodetails = result.data[0]
        this._changeDetectorRef.markForCheck();
      }
    })
  }
  headerformat(item) {
    const data = item.replace("_", " ");
    return data
  }
  processdate(dateData) {
    const data = dateData.split('+')
    // if((this.roData.ro_code == '${this.roData.ro_code}' && this.currentdu == '2') || this.roData.ro_code == "RO0028"){
    var dataa = data[0]
    var timee = []
    if (dataa && dataa.indexOf('Z') === -1) { dataa += 'Z'; }
    const dat = new Date(dataa).toLocaleDateString() + ' ' + new Date(dataa).toLocaleTimeString()
    timee = dat.split(' ')

    return dat
  }
  valueformat(dateData, key) {
    if (key == 'c' || key == 'u' || key.toLowerCase() == 'created' || key.toLowerCase() == 'updated') {
      const data = dateData.split('+')
      // if((this.roData.ro_code == '${this.roData.ro_code}' && this.currentdu == '2') || this.roData.ro_code == "RO0028"){
      var dataa = data[0]
      var timee = []
      if (dataa && dataa.indexOf('Z') === -1) { dataa += 'Z'; }
      const dat = new Date(dataa).toLocaleDateString() + ' ' + new Date(dataa).toLocaleTimeString()
      // timee = dat.split(' ')

      return dat
    }
    else {
      return dateData
    }
  }
  tanks() {
    const d = this.postData;
    if (this.approvalValue === 'approve') {
      d['status'] = "Documents Verified"
    } else {
      d['status'] = "Documents Rejected";
      d['reason'] = this.rejectionReason;
    }
    console.log('d', d);
    const confirmation = this._fuseConfirmationService.open({
      title: `Update vendor ${d.vendor_name}`,
      message: `Are you sure you want to save the changes status to vendor ${d.vendor_name}?`,
      actions: {
        confirm: {
          label: 'Save'
        }
      }
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this.api.doRequest(`/api/vendor/vendor_creation`, 'post', d).subscribe((result: any) => {
          if (result && result.status === 'Success') {
            this.toast.sToast('success', `Vendor updated successfully`)
            this.matDialogRef.close();
          } else {
            if (result && result.msg) {
              this.toast.sToast('error', result.msg)
            }
          }
        }, error => {
          console.log("error", JSON.stringify(error))
        })
      }
    })
  }

  Close() {
    this.matDialogRef.close();
  }

  ngOnDestroy(): void {

  }
  onDecisionChange(value) {
    this.rejectionReason = ""
  }
}

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, debounceTime, fromEvent, map, merge, Observable, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { MyToastrService } from 'app/_services/toastr.service';
import {MatSort, Sort} from '@angular/material/sort';
import { CommonService } from 'app/_services/common.services';
import {default as _rollupMoment} from 'moment';
import * as _moment from 'moment';
import { keyBy } from 'lodash';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-vehicledet',
  templateUrl: './vehicledet.component.html',
  styleUrls: ['./vehicledet.component.scss'],
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations     : fuseAnimations

})
export class VehicledetComponent implements OnInit, AfterViewInit, OnDestroy{
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private sort: MatSort; 
    @Input() rocode:any

    loadingapi:boolean=true
    // du_transactions$: Subscription;
    du_transactions=[];
    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = false;
    pagination={endIndex: 0,
        lastPage: 0,
        length: 0,
        page: 0,
        size: 10,
        startIndex: 0};
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    selectedProduct:any={}
    headers=[];
    smheaders:any=[
      {
        header:'CC Code',
        column:'ro_code',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:' truncate',
        type:'text',
        issearch:true,
        searchstring:'ro_code.keyword'
      },
      {
        header:'Product Code',
        column:'product_code',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:' truncate',
        type:'text',
        issearch:true,
        searchstring:'product_code.keyword'
      },
      {
        header:'Txn Amount',
        column:'transaction_amount',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:' truncate',
        type:'text',
        issearch:true,
        searchstring:'transaction_amount'
      },
      {
        header:'Details',
        column:'details',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:' truncate',
        type:'action'
      }
    ]
    xlheaders:any=[
      {
        header:'CC Code',
        column:'ro_code',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:' truncate',
        type:'text',
        issearch:true,
        searchstring:'ro_code.keyword'
      },
      {
        header:'Txn Amount',
        column:'transaction_amount',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:' truncate',
        type:'text',
        issearch:true,
        searchstring:'transaction_amount'
      },
      {
        header:'Txn Volume',
        column:'transaction_volume',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:'truncate',
        type:'text',
        issearch:true,
        searchstring:'transaction_volume'
      },
      {
        header:'End Totalizer Volume',
        column:'end_totalizer_volume',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:'truncate',
        type:'text',
        issearch:true,
        searchstring:'end_totalizer_volume'
      },
      // {
      //   header:'Attendant Name',
      //   column:'attendant_name',
      //   filter:'',
      //   sort:true,
      //   visible:true,
      //   class:'',
      //   classtab:'truncate',
      //   type:'text',
      //   issearch:true,
      //   searchstring:'attendant_name.keyword'
      // },
      {
        header:'Shift Name',
        column:'shift_name',
        filter:'',
        sort:true,
        visible:true,
        class:'hidden md:block',
        classtab:' truncate hidden md:block',
        type:'text',
        issearch:true,
        searchstring:'shift_name.keyword'
      },
      {
        header:'Product Code',
        column:'product_code',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:' truncate',
        type:'text',
        issearch:true,
        searchstring:'product_code.keyword'
      },
      // {
      //   header:'Payment',
      //   column:'method_of_payment',
      //   filter:'',
      //   sort:true,
      //   visible:true,
      //   class:'hidden md:block',
      //   classtab:' truncate hidden md:block',
      //   type:'text'
      // },
      {
        header:'Txn start Date',
        column:'transaction_start_datetime',
        filter:'epochToDate',
        sort:true,
        visible:true,
        class:'hidden md:block',
        classtab:' truncate hidden md:block',
        type:'date',
        issearch:true,
        showCalander:true
      },
      {
        header:'Txn End Date',
        column:'transaction_end_datetime',
        filter:'epochToDate',
        sort:true,
        visible:true,
        class:'hidden md:block',
        classtab:' truncate hidden md:block',
        type:'date',
        issearch:true,
        showCalander:true
      },
      {
        header:'Details',
        column:'details',
        filter:'',
        sort:true,
        visible:true,
        class:'',
        classtab:' truncate',
        type:'action'
      },


    ]
    noaccess:any=false;
  total_transactions=''
  private _unsubscribeAll: Subject<any> = new Subject<any>();
    currentsort: any=[];
  ro: string='';
  user: any={};
  colfilter:any={};
    /**
     * Constructor
     */
    refreshtrans:any;
    searchtrans:any;
    constructor(
        public cs:CommonService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: UntypedFormBuilder,
        private api:BaseRequestService,
        private toast: MyToastrService
    )
    {
      // this.cs.current({'tab':'TRANSACTIONS', 'count':0, 'showcount':true})
      if(window.location.href.includes('reports')){
      this.searchtrans = this.cs.searchdata.subscribe(change =>{
        this.colfilter = JSON.parse(JSON.stringify(change))
        if(this.colfilter.transaction_start_datetime){
          let start = (moment(this.colfilter.transaction_start_datetime).subtract({ hours: 5, minutes: 30})).format('YYYY-MM-DDTHH:mm:ss')
          this.colfilter.transaction_start_datetime = start
        }
        if(this.colfilter.transaction_end_datetime){
          let end = (moment(this.colfilter.transaction_end_datetime).subtract({ hours: 5, minutes: 30})).format('YYYY-MM-DDTHH:mm:ss')
          this.colfilter.transaction_end_datetime = end
        }
        this.get_transactions()
      }) 
      this.refreshtrans = this.cs.refreshed.subscribe(change =>{
        this.get_transactions()
      })  
    }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
      if(this.cs.accessURLs.includes(window.location.pathname)){
        this.ro = localStorage.getItem('loggedinro')
        this.user = JSON.parse(localStorage.getItem('user'))
        this.get_transactions();
        let breakpoints = {
          '(min-width: 1200px)': 'xl',
          '(min-width: 992px) and (max-width: 1199.98px)': 'lg',
          '(min-width: 768px) and (max-width: 991.98px)': 'md',
          '(min-width: 576px) and (max-width: 767.98px)': 'sm',
          '(max-width: 575.98px)': 'xs',
      }
      for (let media in breakpoints) {
        if (window.matchMedia(media).matches) {
          if(['xl','lg'].includes(breakpoints[media])){
            this.headers = this.xlheaders;
            this.cs.columnsearch(this.headers)
            this._changeDetectorRef.markForCheck();
          }
          else if(!['xl','lg'].includes(breakpoints[media])){
            this.headers = this.smheaders;
            this.cs.columnsearch(this.headers)
            this._changeDetectorRef.markForCheck();
          }
        }
      }}
      else{
        // this.cs.current({'tab':'TRANSACTIONS', 'count':0, 'showcount':true})
        this.noaccess= true;
      }
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void
    {
      const resize$ = fromEvent(window, 'resize');
      resize$
        .pipe(
          map((i: any) => i),
          debounceTime(500) // He waits > 0.5s between 2 events emitted before running the next.
        )
        .subscribe((event) => {
          
      let breakpoints = {
              '(min-width: 1200px)': 'xl',
              '(min-width: 992px) and (max-width: 1199.98px)': 'lg',
              '(min-width: 768px) and (max-width: 991.98px)': 'md',
              '(min-width: 576px) and (max-width: 767.98px)': 'sm',
              '(max-width: 575.98px)': 'xs',
          }
          for (let media in breakpoints) {
            if (window.matchMedia(media).matches) {
              if(['xl','lg'].includes(breakpoints[media])){
                this.headers = this.xlheaders;
                this.cs.columnsearch(this.headers)
                this._changeDetectorRef.markForCheck();
              }
              else if(!['xl','lg'].includes(breakpoints[media])){
                this.headers = this.smheaders;
                this.cs.columnsearch(this.headers)
                this._changeDetectorRef.markForCheck();
              }
            }
          }
    })
    }

    toggleDetails(transaction:any): void
    {   
        if(this.selectedProduct && transaction._id === this.selectedProduct._id){
            this.selectedProduct={};
        }
        else{
        this.fetchro(transaction.ro_code);
        this.selectedProduct = transaction
        }
        this._changeDetectorRef.markForCheck();
    }

    fetchro(rocode){
        let q = {"ro_code": String(rocode)}
        this.api.doRequest(`/api/ro_onboard/pull_romaster`, 'post', q).subscribe((result: any) => {
          if(result && result.data && result.data.length>0 && result.status == 'success'){
            this.selectedProduct.rodetails = result.data[0]
            this._changeDetectorRef.markForCheck();
          }
        })
    }
    /**
     * Close the details
     */
    closeDetails(): void
    {
        this.selectedProduct = null;
    }

    processdateonly(dateData){
      const data = dateData.split('+')
      // if((this.roData.ro_code == '${this.roData.ro_code}' && this.currentdu == '2') || this.roData.ro_code == "RO0028"){
      var dataa = data[0]
      var timee=[]
          if (dataa && dataa.indexOf('Z') === -1) { dataa += 'Z'; }
          const dat = new Date(dataa).toLocaleDateString()
          timee = dat.split(' ')

      return dat
    }

    processdatetime(dateData){
      const data = dateData.split('+')
      // if((this.roData.ro_code == '${this.roData.ro_code}' && this.currentdu == '2') || this.roData.ro_code == "RO0028"){
      var dataa = data[0]
      var timee=[]
          if (dataa && dataa.indexOf('Z') === -1) { dataa += 'Z'; }
          const dat = new Date(dataa).toLocaleTimeString('en-US', {hour12: true})
          timee = dat.split(' ')

      return dat
    }
  
    /**
     * Toggle the tags edit mode
     */
    toggleTagsEditMode(): void
    {
        
    }

    /**
     * Filter tags
     *
     * @param event
     */
    filterTags(event): void
    {
        // Get the value
        const value = event.target.value.toLowerCase();
    }

    /**
     * Filter tags input key down event
     *
     * @param event
     */
    filterTagsInputKeyDown(event): void
    {
            return;
    }

    /**
     * Create a new tag
     *
     * @param title
     */
    createTag(title: string): void
    {
    }

    /**
     * Update the tag title
     *
     * @param tag
     * @param event
     */
    updateTagTitle(tag, event): void
    {
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Delete the tag
     *
     * @param tag
     */
    deleteTag(tag): void
    {
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Add tag to the product
     *
     * @param tag
     */
    addTagToProduct(tag): void
    {
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Remove tag from the product
     *
     * @param tag
     */
    removeTagFromProduct(tag): void
    {
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Toggle product tag
     *
     * @param tag
     * @param change
     */
    toggleProductTag(tag, change: MatCheckboxChange): void
    {
    }

    /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
    shouldShowCreateTagButton(inputValue: string)
    {
    }

    /**
     * Create product
     */
    createProduct(): void
    {
            this._changeDetectorRef.markForCheck();
    }

    /**
     * Update the selected product using the form data
     */
    updateSelectedProduct(): void
    {
    }

    /**
     * Delete the selected product using the form data
     */
    deleteSelectedProduct(): void
    {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title  : 'Delete product',
            message: 'Are you sure you want to remove this product? This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete'
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {
        })
    }

    /**
     * Show flash message
     */
    showFlashMessage(type: 'success' | 'error'): void
    {
        // Show the message
        this.flashMessage = type;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Hide it after 3 seconds
        setTimeout(() => {

            this.flashMessage = null;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }, 3000);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    getPageDetails(event){
        this.pagination.page = event.pageIndex
        this.pagination.size = event.pageSize
        this.get_transactions()
    }

    // customSort(event){
    //     console.log("event sort", event)
    //     this.currentsort = []
    //     const field = event.active
    //     const fi = `${field}.keyword`
    //     let currentsort:any=[{}];
    //     currentsort[0][fi] ={"order":event.direction}
    //     this.currentsort = currentsort
    //     this.get_transactions()
    // }

    customSort(event){
      this.currentsort = []
      const field = event.active
      this.headers.forEach( ele =>{
        if(ele.column == field){
          let currentsort:any=[{}];
          currentsort[0][ele.searchstring] ={"order":event.direction}
          this.currentsort = currentsort
          this.get_transactions()    
        }
      })
    }

    


    getsearchhistory(){
      // this.cs.searchfilfer.subscribe
     
    }

    get_transactions(){
      if(window.location.href.includes('reports')){
      var q:any = {skip:this.pagination.page, limit:this.pagination.size}
      if(this.currentsort && this.currentsort.length>0){
        q.sort = this.currentsort
      }
      else{
        q.sort = [{"transaction_end_datetime":{"order":"desc"}}]
      }
      q.q= {"query": {
        "bool": {
          "must":[ 
                  {"match": {"_type_.keyword": 'dutransaction'}},
                  {"exists":{"field":"blob_url"}},
                  {"match": {"ro_code.keyword": '244802'}},
                  {"match": {"du_id.keyword": '3'}}
          ],
          "must_not":[{"match":{"blob_url.keyword":""}}]
        }}}
      if(this.user && this.user.role && this.user.role !== 'ClientAdmin' && this.user.role !== 'SuperAdmin'   && this.user.role !== 'ServerAdmin'  && this.user.role !== 'Viewer'){
        q.q= {"query": {
          "bool": {
            "must":[ 
                    {"match": {"ro_code.keyword": this.ro}},
                    {"match": {"_type_.keyword": 'dutransaction'}},
                    {"exists":{"field":"blob_url"}},
                    {"match": {"ro_code.keyword": '244802'}},
                    {"match": {"du_id.keyword": '3'}}
            ],
            "must_not":[{"match":{"blob_url.keyword":""}}]
          }}}
      }
      // if(this.colfilter && Object.keys(this.colfilter).length>0){
      //   Object.keys(this.colfilter).forEach(key => {
      //     if(this.colfilter[key] && this.colfilter[key].length>0){
      //       this.headers.forEach(ele =>{
      //         if(ele.column === key){
      //           q.q.query.bool.must.push(
      //             {"match": { [ele.searchstring] :  this.colfilter[key]}}
      //         )}   
      //         })
      //       }
      //   });
      // }
      if(this.colfilter && Object.keys(this.colfilter).length>0){
        Object.keys(this.colfilter).forEach(key => {
          if(this.colfilter[key] && this.colfilter[key].length>0){
            
            if(!q.q.query.bool.filter){
              q.q.query.bool.filter=[];
            }
            this.headers.forEach(ele =>{
              if(ele.column === key){
                if(!ele.showCalander && ele.searchstring.includes('keyword')){
                  q.q.query.bool.must.push(
                    {
                      "query_string": {
                        "default_field": key,
                        "query": `*${this.colfilter[key]}*`
                      }
                    }
                  )
              }
              else if(!ele.showCalander ){
                try
                {
                q.q.query.bool.must.push({
                  "range": {
                    [key]: {
                      "gte": Number(this.colfilter[key]),
                      "lte": Number(this.colfilter[key])+1
                        }
                  }
                }
                )
              }
              catch{
                this.toast.sToast("warning", `Invalid value for ${key}`)
              }
            }   
            }})}})}
            
              if(this.colfilter['transaction_start_datetime'] && this.colfilter['transaction_start_datetime'] != null){
              q.q.query.bool.must.push(
                {
                  "range":{
                    'transaction_start_datetime':{
                      "gte":this.colfilter['transaction_start_datetime']
                    }
                  }
                }
              )
            }
            if(this.colfilter['transaction_end_datetime'] && this.colfilter['transaction_end_datetime'] != null){
              q.q.query.bool.must.push(
                {
                  "range":{
                    ['transaction_end_datetime']:{
                      "lte":this.colfilter['transaction_end_datetime']
                    }
                  }
                }
              )
            }
          
        // });
      
    
      this.loadingapi = true;
      this.api.doRequest(`/api/dutransaction`, 'get', null, q).subscribe((result: any) => {
        this.loadingapi = false;
        if(window.location.href.includes('reports')){ 
        if(result && result.data && result.data.length>0){
          result.data.forEach(ele =>{
            if(ele.blob_url.includes(".jpgsv")){
              ele.blob_url=  ele.blob_url.replace(".jpgsv", ".jpg?sv")
              console.log(ele.blob_url)
            }
          })
          this.du_transactions = result.data
          this.total_transactions = result.total
          this.pagination.length = result.total
          // this.cs.current({'tab':'TRANSACTIONS', 'count':result.total, 'showcount':true})
          this._changeDetectorRef.markForCheck();
        }
        else{
            this.du_transactions = []
            // this.cs.current({'tab':'TRANSACTIONS', 'count':0, 'showcount':true})
          this.total_transactions = ''
          this.pagination.length = result.total
          this.total_transactions = result.total  
          this._changeDetectorRef.markForCheck();
        }}
      }, error =>{
        if(window.location.href.includes('reports')){
        // this.cs.current({'tab':'TRANSACTIONS', 'count':0, 'showcount':true})
        this.loadingapi = false;
        this._changeDetectorRef.markForCheck();
        if(error && error.msg){
        this.toast.sToast('error', error.msg)}}
      })
    }
    }

    ngOnDestroy(): void {
      // this.cs.current({'tab':'', 'count': 0, 'showcount':false})
      this.cs.searchfiler({})
      this.cs.columnsearch([])
      this.searchtrans.unsubscribe()
      this.refreshtrans.unsubscribe();
      }

}


<!-- <div class="mt-6 ml-6 mb-4 flex flex-col items-center">
    <div class="flex flex-0 items-center justify-between h-10 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            Mass Flow Meter</div>
        <button (click)="Close()" mat-icon-button [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div class="pr-2 w-80">
        <mat-form-field appearance="fill" class="w-full">
            <mat-label>Mass Flow Meter Interval</mat-label>
            <mat-select class="p-2" [(ngModel)]="massInterval" name="massInterval" placeholder="Select Interval">
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="10">10</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <button class="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" mat-flat-button
        (click)="submitInterval()">
        Submit
    </button>
</div> -->

<div class="flex flex-col max-w-80 md:min-w-80 -m-6 mb-4" *ngIf="massdata && massdata.length>0">
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            Mass Flow Meter</div>
        <button (click)="Close()" mat-icon-button [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div *ngIf="massdata && massdata.length ===1 " class="mt-6 ml-6 mb-4 flex flex-row items-center justify-center">
        <div>
            <div class="pr-2 w-60">
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>MFM</mat-label>
                    <mat-select class="p-2" [(ngModel)]="massInterval1" name="massInterval1"
                        placeholder="Select Interval">
                        <mat-option [value]="2">2 min</mat-option>
                        <mat-option [value]="5">5 min</mat-option>
                        <mat-option [value]="10">10 min</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex justify-center">
                <button class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    mat-flat-button (click)="submitTotalInterval()">
                    Submit
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="massdata && massdata.length >1" class="mt-6 ml-6 mb-4 flex flex-row items-center justify-center">
        <div>
            <div class="pr-2 w-60">
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>MFM 1</mat-label>
                    <mat-select class="p-2" [(ngModel)]="massInterval1" name="massInterval1"
                        placeholder="Select Interval">
                        <mat-option [value]="2">2 min</mat-option>
                        <mat-option [value]="5">5 min</mat-option>
                        <mat-option [value]="10">10 min</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="pr-2 w-60">
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>MFM 2</mat-label>
                    <mat-select class="p-2" [(ngModel)]="massInterval2" name="massInterval2"
                        placeholder="Select Interval">
                        <mat-option [value]="2">2 min</mat-option>
                        <mat-option [value]="5">5 min</mat-option>
                        <mat-option [value]="10">10 min</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="pr-2 w-60">
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>MFM 3</mat-label>
                    <mat-select class="p-2" [(ngModel)]="massInterval3" name="massInterval3"
                        placeholder="Select Interval">
                        <mat-option [value]="2">2 min</mat-option>
                        <mat-option [value]="5">5 min</mat-option>
                        <mat-option [value]="10">10 min</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex justify-center">
                <button class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    mat-flat-button (click)="submitTotalInterval()">
                    Submit
                </button>
            </div>
        </div>

    </div>
</div>
<!--suppress TypeScriptUnresolvedVariable -->
<mat-card class="bg-card shadow rounded-2xl w-full h-full" *ngIf="isLoading">
  <mat-card-content>
    <p><i class="fa fa-spin fa-spinner fa-lg"></i> Loading...</p>
  </mat-card-content>
</mat-card>
<mat-card class="p-0 bg-card shadow rounded-2xl mat-elevation-z3" *ngIf="!isLoading">
  <mat-drawer-container class="flex-auto sm:h-full">
    <!-- <mat-drawer class="sm:w-96 block md:hidden dark:bg-gray-900" [autoFocus]="false" [mode]="drawerMode"
      [opened]="drawerOpened" position="end" #drawer>
      <div class="flex items-center flex-row my-2 mx-2 sm:my-10">
        <div class="text-2xl font-bold tracking-tight leading-none">
          Settings
        </div>
        <div class="spacer"></div>
        <div class="lg:hidden">
          <button mat-icon-button (click)="drawer.close()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
          </button>
        </div>
      </div>
      <div class="flex flex-col divide-y border-t border-b" *ngIf="tableOptions.floatingFilter">
        <div fxLayoutGap="15px" class="navigation-items">
          <form fxLayout="column" fxLayoutAlign="center center">
            <mat-form-field class="pl20" style="padding: 10px; font-size: 15px" *ngIf="tableOptions.dropdwn">
              <mat-select [multiple]="false" placeholder="Asset" (selectionChange)="filterDrpLstChange($event.value)"
                [formControl]="assetDrpCtrl">
                <mat-option *ngFor="let item of tableOptions.filterDrpLstFilteredList" [value]="item._id">
                  {{ item.ip }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field floatLabel="never"
              class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64"
              *ngIf="tableOptions.search">
              <input class="ml-3"  matInput class="form-control" [(ngModel)]="filterText" type="text" trim="blur" name="filterText"
                [id]="tableOptions.id" (ngModelChange)="filterUpdate.next($event)" placeholder="Search"
                autocomplete="off" />
              <button class="gray" matSuffix mat-icon-button *ngIf="!filterText || filterText === ''" color="primary">
                <mat-icon>search</mat-icon>
              </button>
              <button class="gray" matSuffix type="button" mat-icon-button *ngIf="filterText && filterText !== ''"
                (click)="filterText = ''; doFilter(filterText)" color="primary">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div fxFlex fxLayout fxLayoutAlign="end" *ngIf="tableOptions.refreshData">
              <button class="gray" matSuffix mat-icon-button matTooltip="Reload Table Data" (click)="RefreshTableData()"
                color="primary">
                <mat-icon id="rfrbtn{{ tableOptions.title }}">refresh</mat-icon>
              </button>
            </div>
            <div *ngIf="tableOptions.refreshData && false">
              <mat-form-field class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                <mat-select [(value)]="selectedTimer" (selectionChange)="timerCallData()" matTooltip="Refresh Interval">
                  <mat-option value="0" selected>No Refresh</mat-option>
                  <mat-option value="60" id="RefreshOnemin">1 Min</mat-option>
                  <mat-option value="300" id="RefreshFivemin">5 Min</mat-option>
                  <mat-option value="1800" id="RefreshThirtymin">30 Min</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-menu #filterMenu="matMenu" class="col-filters">
              <mat-card class="filter-card" (click)="$event.stopPropagation()">
                <mat-card-header>
                  <mat-card-title>Filter</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <form>
                    <ng-container *ngFor="let column of _sTableOptions.columns">
                      {{ column.type }}
                      <mat-form-field *ngIf="column.iscolumnSearch" class="w-full">
                        <input class="ml-3"  matInput type="text" trim="blur" [id]="column.id"
                          placeholder="Search {{ column.header }}" *ngIf="!column.selectFilter && column.type"
                          name="{{ column.columnDef }}" [(ngModel)]="colfilter[column.columnDef]" (input)="
                            columnFilter(
                              $any($event.target).value,
                              column.columnDef
                            )
                          " [type]="column.type" [disabled]="
                            column.iscolumnSearch === undefined ||
                            column.iscolumnSearch === null
                              ? 'false'
                              : !column.iscolumnSearch
                          " />
                        <input class="ml-3"  matInput type="text" trim="blur" [id]="column.id"
                          placeholder="Search {{ column.header }}" *ngIf="!column.selectFilter && !column.type"
                          name="{{ column.columnDef }}" [(ngModel)]="colfilter[column.columnDef]" (input)="
                            columnFilter(
                              $any($event.target).value,
                              column.columnDef
                            )
                          " [disabled]="
                            column.iscolumnSearch === undefined ||
                            column.iscolumnSearch === null
                              ? 'false'
                              : !column.iscolumnSearch
                          " />
                        <mat-select placeholder="Choose {{ column.header }}" name="{{ column.columnDef }}"
                          *ngIf="column.selectFilter" [(ngModel)]="colfilter[column.columnDef]" [disabled]="
                            column.iscolumnSearch === undefined ||
                            column.iscolumnSearch === null
                              ? 'false'
                              : !column.iscolumnSearch
                          " (selectionChange)="
                            columnFilter($event.value, column.columnDef)
                          ">
                          <mat-option value="">All</mat-option>
                          <mat-option *ngFor="let val of column.selectFilterArr" [value]="val.value" [id]="val.id">{{
                            val.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </form>
                </mat-card-content>
              </mat-card>
            </mat-menu>
            <mat-menu #belowMenu="matMenu" [hasBackdrop]="false" yPosition="below">
              <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div class="flex" *ngFor="let item of showhideList; let i = index" cdkDrag>
                  <i class="material-icons color_icon mr-1"> drag_handle </i>
                  <mat-checkbox color="primary" [checked]="item.visible === true ? item : ''"
                    (click)="$event.stopPropagation()" (change)="showHideColumn(i, item)" class="left-align"
                    [id]="item.header">
                    {{ item.header }}
                  </mat-checkbox>
                </div>
              </div>
            </mat-menu>
            <mat-menu #filterMenu="matMenu" class="col-filters">
              <mat-card class="filter-card" (click)="$event.stopPropagation()">
                <mat-card-header>
                  <mat-card-title>Filter</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <form #filterForm="ngForm" role="form">
                    <mat-form-field *ngIf="
                        sTableOptions.columns && sTableOptions.columns.length
                      " class="w-full" appearance="outline">
                      <mat-label>Choose filter column</mat-label>
                      <mat-select placeholder="Choose filter column" name="chooseFilter" required
                        [(ngModel)]="cFilter.selectedCol">
                        <mat-option value="">Choose filter column</mat-option>
                        <mat-option *ngFor="let val of sTableOptions.columns" [value]="val.col" [id]="val.id">{{
                          val.header }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="cFilter.selectedCol" class="w-full" appearance="outline">
                      <mat-label>Search
                        {{ colHash[cFilter.selectedCol].header }}</mat-label>
                      <input class="ml-3"  matInput type="text" trim="blur" *ngIf="
                          colHash[cFilter.selectedCol].colFilters.type ===
                          'text'
                        " placeholder="Search {{
                          colHash[cFilter.selectedCol].header
                        }}" required name="{{ colHash[cFilter.selectedCol].col }}s"
                        [(ngModel)]="colHash[cFilter.selectedCol].val" />
                      <mat-select placeholder="Search {{
                          colHash[cFilter.selectedCol].header
                        }}" name="{{ colHash[cFilter.selectedCol].col }}s" *ngIf="
                          colHash[cFilter.selectedCol].colFilters.type ===
                          'select'
                        " required [(ngModel)]="colHash[cFilter.selectedCol].val">
                        <mat-option *ngFor="
                            let val of colHash[cFilter.selectedCol].colFilters
                              .options
                          " [value]="val.value">{{ val.name }}</mat-option>
                      </mat-select>
                      <ng-container *ngIf="
                          colHash[cFilter.selectedCol].colFilters.type ===
                          'range'
                        ">
                        <mat-form-field>
                          <input class="ml-3"  matInput [matDatepicker]="picker1" required [(ngModel)]="
                              colHash[cFilter.selectedCol].dateCol.start
                            " placeholder="From Date" name="dtInpt1" />
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                          <input class="ml-3"  matInput [matDatepicker]="picker2" required [(ngModel)]="
                              colHash[cFilter.selectedCol].dateCol.end
                            " placeholder="To Date" name="dtInpt" />
                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                      </ng-container>
                    </mat-form-field>
                    <button (click)="columnFilterxlsx(colHash[cFilter.selectedCol])" [disabled]="!filterForm.form.valid"
                      color="primary" mat-raised-button="">
                      Add Filter
                    </button>
                    <button class="ml-2" (click)="cFilter.selectedCol = ''" mat-stroked-button="">
                      Clear
                    </button>
                  </form>
                </mat-card-content>
              </mat-card>
            </mat-menu>
            <button id="gbtn{{ sTableOptions.title }}" (click)="apiDownload('type')" color="primary" matSuffix
              mat-icon-button *ngIf="tableOptions.apiDownload && tableOptions.pageTotal !== 0" matTooltip="Download"
              aria-label="download xlsx menu">
              <svg viewBox="0 0 24 24" height="24" width="24"
                xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <style>
                    .prefix__cls-1 {
                      fill: #0076c0;
                    }
                  </style>
                </defs>
                <g id="prefix__excel" transform="translate(0 -16.01)">
                  <g id="prefix__Group_699" data-name="Group 699" transform="translate(0 16.01)">
                    <g id="prefix__Group_698" data-name="Group 698">
                      <path id="prefix__Path_1049"
                        d="M12.131 16.193a.623.623 0 0 0-.565-.17l-11 2.4a.775.775 0 0 0-.566.786v17.6a.777.777 0 0 0 .561.786l11 2.4a.569.569 0 0 0 .127.014.632.632 0 0 0 .439-.184.858.858 0 0 0 .249-.616v-22.4a.855.855 0 0 0-.245-.616zM11 38.245l-9.629-2.1V19.873L11 17.774z"
                        class="prefix__cls-1" data-name="Path 1049" transform="translate(0 -16.01)" />
                    </g>
                  </g>
                  <g id="prefix__Group_701" data-name="Group 701" transform="translate(12.996 18.76)">
                    <g id="prefix__Group_700" data-name="Group 700">
                      <path id="prefix__Path_1050"
                        d="M266.316 79.99h-9.629a.858.858 0 0 0 0 1.682h8.941v15.135h-8.941a.858.858 0 0 0 0 1.682h9.629a.776.776 0 0 0 .688-.841V80.831a.776.776 0 0 0-.688-.841z"
                        class="prefix__cls-1" data-name="Path 1050" transform="translate(-256 -79.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_705" data-name="Group 705" transform="translate(12.23 21.884)">
                    <g id="prefix__Group_704" data-name="Group 704">
                      <path id="prefix__Path_1052"
                        d="M259.439 207.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1052" transform="translate(-256 -207.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_707" data-name="Group 707" transform="translate(12.23 26.584)">
                    <g id="prefix__Group_706" data-name="Group 706">
                      <path id="prefix__Path_1053"
                        d="M259.439 271.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1053" transform="translate(-256 -271.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_709" data-name="Group 709" transform="translate(12.23 31.284)">
                    <g id="prefix__Group_708" data-name="Group 708">
                      <path id="prefix__Path_1054"
                        d="M259.439 335.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1054" transform="translate(-256 -335.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_713" data-name="Group 713" transform="translate(18.498 21.884)">
                    <g id="prefix__Group_712" data-name="Group 712">
                      <path id="prefix__Path_1056"
                        d="M386.063 207.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1056" transform="translate(-384 -207.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_715" data-name="Group 715" transform="translate(18.498 26.584)">
                    <g id="prefix__Group_714" data-name="Group 714">
                      <path id="prefix__Path_1057"
                        d="M386.063 271.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1057" transform="translate(-384 -271.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_717" data-name="Group 717" transform="translate(18.498 31.284)">
                    <g id="prefix__Group_716" data-name="Group 716">
                      <path id="prefix__Path_1058"
                        d="M386.063 335.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1058" transform="translate(-384 -335.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_719" data-name="Group 719" transform="translate(3.872 24.57)">
                    <g id="prefix__Group_718" data-name="Group 718">
                      <path id="prefix__Path_1059"
                        d="M86.025 181.721l-4.814-5.5a.688.688 0 0 0-1.036.905l4.814 5.5a.688.688 0 0 0 1.036-.905z"
                        class="prefix__cls-1" data-name="Path 1059" transform="translate(-80.004 -175.984)" />
                    </g>
                  </g>
                  <g id="prefix__Group_721" data-name="Group 721" transform="translate(3.872 23.794)">
                    <g id="prefix__Group_720" data-name="Group 720">
                      <path id="prefix__Path_1060"
                        d="M85.924 160.138a.688.688 0 0 0-.966.12l-4.814 6.19a.688.688 0 0 0 1.085.846l4.814-6.19a.687.687 0 0 0-.119-.966z"
                        class="prefix__cls-1" data-name="Path 1060" transform="translate(-79.999 -159.992)" />
                    </g>
                  </g>
                </g>
              </svg>
            </button>
            <button id="gbtn{{ sTableOptions.title }}" (click)="downloadAsXls('full')" color="primary" matSuffix
              mat-icon-button *ngIf="!tableOptions.hideDownload && tableOptions.pageTotal !== 0" matTooltip="Download"
              aria-label="download xlsx menu">
              <svg viewBox="0 0 24 24" height="24" width="24"
                xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <style>
                    .prefix__cls-1 {
                      fill: #0076c0;
                    }
                  </style>
                </defs>
                <g id="prefix__excel" transform="translate(0 -16.01)">
                  <g id="prefix__Group_699" data-name="Group 699" transform="translate(0 16.01)">
                    <g id="prefix__Group_698" data-name="Group 698">
                      <path id="prefix__Path_1049"
                        d="M12.131 16.193a.623.623 0 0 0-.565-.17l-11 2.4a.775.775 0 0 0-.566.786v17.6a.777.777 0 0 0 .561.786l11 2.4a.569.569 0 0 0 .127.014.632.632 0 0 0 .439-.184.858.858 0 0 0 .249-.616v-22.4a.855.855 0 0 0-.245-.616zM11 38.245l-9.629-2.1V19.873L11 17.774z"
                        class="prefix__cls-1" data-name="Path 1049" transform="translate(0 -16.01)" />
                    </g>
                  </g>
                  <g id="prefix__Group_701" data-name="Group 701" transform="translate(12.996 18.76)">
                    <g id="prefix__Group_700" data-name="Group 700">
                      <path id="prefix__Path_1050"
                        d="M266.316 79.99h-9.629a.858.858 0 0 0 0 1.682h8.941v15.135h-8.941a.858.858 0 0 0 0 1.682h9.629a.776.776 0 0 0 .688-.841V80.831a.776.776 0 0 0-.688-.841z"
                        class="prefix__cls-1" data-name="Path 1050" transform="translate(-256 -79.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_705" data-name="Group 705" transform="translate(12.23 21.884)">
                    <g id="prefix__Group_704" data-name="Group 704">
                      <path id="prefix__Path_1052"
                        d="M259.439 207.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1052" transform="translate(-256 -207.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_707" data-name="Group 707" transform="translate(12.23 26.584)">
                    <g id="prefix__Group_706" data-name="Group 706">
                      <path id="prefix__Path_1053"
                        d="M259.439 271.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1053" transform="translate(-256 -271.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_709" data-name="Group 709" transform="translate(12.23 31.284)">
                    <g id="prefix__Group_708" data-name="Group 708">
                      <path id="prefix__Path_1054"
                        d="M259.439 335.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1054" transform="translate(-256 -335.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_713" data-name="Group 713" transform="translate(18.498 21.884)">
                    <g id="prefix__Group_712" data-name="Group 712">
                      <path id="prefix__Path_1056"
                        d="M386.063 207.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1056" transform="translate(-384 -207.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_715" data-name="Group 715" transform="translate(18.498 26.584)">
                    <g id="prefix__Group_714" data-name="Group 714">
                      <path id="prefix__Path_1057"
                        d="M386.063 271.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1057" transform="translate(-384 -271.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_717" data-name="Group 717" transform="translate(18.498 31.284)">
                    <g id="prefix__Group_716" data-name="Group 716">
                      <path id="prefix__Path_1058"
                        d="M386.063 335.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                        class="prefix__cls-1" data-name="Path 1058" transform="translate(-384 -335.99)" />
                    </g>
                  </g>
                  <g id="prefix__Group_719" data-name="Group 719" transform="translate(3.872 24.57)">
                    <g id="prefix__Group_718" data-name="Group 718">
                      <path id="prefix__Path_1059"
                        d="M86.025 181.721l-4.814-5.5a.688.688 0 0 0-1.036.905l4.814 5.5a.688.688 0 0 0 1.036-.905z"
                        class="prefix__cls-1" data-name="Path 1059" transform="translate(-80.004 -175.984)" />
                    </g>
                  </g>
                  <g id="prefix__Group_721" data-name="Group 721" transform="translate(3.872 23.794)">
                    <g id="prefix__Group_720" data-name="Group 720">
                      <path id="prefix__Path_1060"
                        d="M85.924 160.138a.688.688 0 0 0-.966.12l-4.814 6.19a.688.688 0 0 0 1.085.846l4.814-6.19a.687.687 0 0 0-.119-.966z"
                        class="prefix__cls-1" data-name="Path 1060" transform="translate(-79.999 -159.992)" />
                    </g>
                  </g>
                </g>
              </svg>
            </button>
            <button mat-icon-button *ngIf="tableOptions.compareData" matTooltip="Compare Data" color="primary"
              (click)="CompareData(compSelection.selected)">
              <mat-icon>compare</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Save Settings" id="save{{ tableOptions.id }}" (click)="SaveSettings()"
              color="primary" *ngIf="tableOptions.saveData">
              <mat-icon>save</mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="publish()" *ngIf="tableOptions.publish">
              Publish
            </button>
            <a mat-icon-button matTooltip="Help Link" [href]="baseService.helpLinks[tableOptions.id]" target="_blank"
              color="primary" *ngIf="
                baseService.helpLinks &&
                baseService.helpLinks[tableOptions.id] &&
                baseService.helpLinks[tableOptions.id] !== ''
              ">
              <mat-icon>help</mat-icon>
            </a>
            <button *ngFor="let pbtn of tableOptions.parentCalls" mat-icon-button [matTooltip]="pbtn.tooltip"
              [color]="pbtn.color" (click)="ParentCalls(pbtn.id)">
              <mat-icon>{{ pbtn.icon }}</mat-icon>
            </button>

            <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
              <div>
                <button mat-button (click)="exportAllData()">
                  Export All Data
                </button>
              </div>
            </mat-menu>
          </form>
        </div>
      </div>
    </mat-drawer> -->
    <mat-drawer-content>
      <div class="hidden md:flex items-center">
        <mat-toolbar class="bg-card border-b w-full" role="heading" *ngIf="selection.selected.length === 0">
          <div class="flex flex-row px-4 py-2 ">
            <span class="text-3xl mt-3 font-extrabold tracking-tight leading-none">
              {{ tableOptions.title }}
            </span>
            <span class="mt-2  ml-2 p-2  tracking-tight leading-none rounded bg-primary text-white">
              {{ tableOptions.pageTotal }}
            </span>
            <div class="spacer"></div>
            <div class="flex flex-row justify-center items-center flex-wrap">
              <button class="px-3 lh-25  fuse-mat-button-small mini-form-field" mat-stroked-button
                *ngIf="tableOptions.add" type="button" matTooltip="Add New Record" color="primary"
                (click)="AddTableRecord()" id="addbtn{{ tableOptions.title }}">
                <mat-icon class="mat-18 mb-1 text-lg">add</mat-icon> Add
              </button>
              <form class="flex flex-row items-center justify-center">
                <mat-form-field floatLabel="never" *ngIf="tableOptions.search"
                  class="mini-form-field fuse-mat-dense mt-5 ml-2 fuse-mat-no-subscript fuse-mat-rounded w-[200px]">
                  <input matInput class="ml-3 form-control" [(ngModel)]="filterText" type="text" trim="blur"
                    name="filterText{{tableOptions.id}}" [id]="tableOptions.id"
                    (ngModelChange)="filterUpdate.next($event)" placeholder="Search">
                  <button class="gray" matSuffix mat-icon-button type="button" *ngIf="!filterText || filterText === ''"
                    color="primary">
                    <mat-icon>search</mat-icon>
                  </button>
                  <!-- <button class="gray" matSuffix mat-icon-button type="button" *ngIf="filterText && filterText !== ''"
                    (click)="filterText = ''; doFilter(filterText);" color="primary">
                    <mat-icon>close</mat-icon>
                  </button> -->
                </mat-form-field>
                <div class="flex flex-row items-end" *ngIf="tableOptions.refreshData">
                  <button class="gray" matSuffix mat-icon-button matTooltip="Reload Table Data"
                    (click)="RefreshTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}" svgIcon="mat_outline:refresh"></mat-icon>
                  </button>
                </div>
                <div class="flex flex-row items-end" *ngIf="tableOptions.showFilter">
                  <button class="gray" matSuffix mat-icon-button matTooltip="Filter Table Data"
                    (click)="FilterTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}">filter_alt</mat-icon>
                  </button>
                </div>
                <div class="flex flex-row items-end" *ngIf="tableOptions.showTagFilter">
                  <button class="gray" matSuffix mat-icon-button matTooltip="Filter by Tags"
                    (click)="FilterTagTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}" svgIcon="heroicons_solid:tag"></mat-icon>
                  </button>
                </div>
                <button mat-icon-button menuOpen="false" color="primary" matTooltip="Filter List" *ngIf="
                    tableOptions.showColFilter &&
                    _sTableOptions.columns &&
                    _sTableOptions.columns.length
                  " [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                  <mat-icon>filter_list</mat-icon>
                </button>
                <button mat-icon-button menuOpen="false" (click)="openListColumns()" #listMenuTrigger="matMenuTrigger"
                  [matMenuTriggerFor]="belowMenu" color="primary" id="view{{tableOptions.id}}"
                  matTooltip="View Column, Reorder Columns" *ngIf="tableOptions.showhideList">
                  <mat-icon>view_column</mat-icon>
                </button>
                <mat-menu #belowMenu="matMenu" [hasBackdrop]="true" yPosition="below" class="p-4">
                  <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div class="flex flex-row items-center w-full" *ngFor="let item of showhideList; let i = index"
                      cdkDrag>
                      <i class="material-icons color_icon mr-1 mt-2 cursor-move"> drag_handle </i>
                      <mat-checkbox color="primary" [checked]="item.visible" (click)="$event.stopPropagation()"
                        (change)="showHideColumn(i, item)" class="left-align" [id]="item.header">
                        {{item.header}} </mat-checkbox>
                    </div>
                  </div>
                </mat-menu>
                <button mat-icon-button menuOpen="false" matTooltip="Filter List" *ngIf="sTableOptions.cFilter"
                  color="primary" [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                  <mat-icon class="">filter_list</mat-icon>
                </button>
                <mat-menu #filterMenu="matMenu" class="col-filters p-4">
                  <mat-card class="filter-card" (click)="$event.stopPropagation();">
                    <mat-card-header>
                      <mat-card-title>Filter</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <form #filterForm="ngForm" role="form">
                        <mat-form-field *ngIf="sTableOptions.columns && sTableOptions.columns.length" class="w-full"
                          appearance="outline">
                          <mat-label>Choose filter column</mat-label>
                          <mat-select placeholder="Choose filter column" name="chooseFilter" required
                            [(ngModel)]="cFilter.selectedCol">
                            <mat-option value="">Choose filter column</mat-option>
                            <ng-container *ngFor="let val of sTableOptions.columns">
                              <mat-option *ngIf="val.colFilters?.type === 'text'&& val.iscolumnSearch"
                                [value]="val.columnDef" [id]="val.id">
                                {{ val.header }}
                              </mat-option>
                            </ng-container>
                            <!-- <mat-option *ngFor="let val of sTableOptions.columns" [value]="val.columnDef"
                              [id]="val.id">{{val.header}}</mat-option> -->
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="cFilter.selectedCol" class="w-full" appearance="outline">
                          <mat-label>
                            Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                          <input class="ml-3" matInput type="text" trim="blur"
                            *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                            placeholder="Search {{colHash[cFilter.selectedCol].header}}" required
                            name="{{colHash[cFilter.selectedCol].col}}s" [(ngModel)]="colHash[cFilter.selectedCol].val">
                          <mat-select placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                            name="{{colHash[cFilter.selectedCol].col}}s"
                            *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'" required
                            [(ngModel)]="colHash[cFilter.selectedCol].val">
                            <mat-option *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options"
                              [value]="val.value">{{val.name}}</mat-option>
                          </mat-select>
                          <!-- <ng-container *ngIf="cFilter.selectedCol && colHash[cFilter.selectedCol]">
                            <ng-container *ngIf="colHash[cFilter.selectedCol]?.colFilters?.type === 'date'">
                              <input matInput placeholder="Select Date and Time" onkeypress="return false"
                              name="{{colHash[cFilter.selectedCol].col}}s" id="{{colHash[cFilter.selectedCol].col}}"
                              [(ngModel)]="colHash[cFilter.selectedCol].val"
                              [ngxMatDatetimePicker]="picker" name="{{colHash[cFilter.selectedCol].col}}r"
                              id="{{colHash[cFilter.selectedCol].col}}" placeholder="Select Date and Time">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <ngx-mat-datetime-picker #picker [stepHour]="stepHour" [stepMinute]="stepMinute"
                              [stepSecond]="stepSecond" [touchUi]="touchUi" [color]=""
                              [enableMeridian]="enableMeridian"></ngx-mat-datetime-picker>
                            </ng-container>
                          </ng-container> -->

                        </mat-form-field>
                        <!-- <mat-form-field
                          *ngIf="cFilter.selectedCol && colHash[cFilter.selectedCol] && colHash[cFilter.selectedCol]?.colFilters?.type === 'date'"
                          class="w-full" appearance="outline">
                          <mat-label>
                            Choose {{colHash[cFilter.selectedCol].header}}</mat-label>
                          <input matInput placeholder="Select Date and Time"
                            name="{{colHash[cFilter.selectedCol].col}}s" [(ngModel)]="colHash[cFilter.selectedCol].val"
                            readonly (click)="picker.open()" [ngxMatDatetimePicker]="picker"
                            #picker="ngxMatDatetimePicker">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <ngx-mat-datetime-picker #picker [stepHour]="stepHour" [stepMinute]="stepMinute"
                            [stepSecond]="stepSecond" [touchUi]="touchUi" [enableMeridian]="enableMeridian">
                          </ngx-mat-datetime-picker>
                        </mat-form-field> -->
                        <button (click)="columnFilterxlsx(colHash[cFilter.selectedCol])"
                          [disabled]="!filterForm.form.valid" color="primary" mat-raised-button="">Add Filter
                        </button>
                        <!-- <button class="ml-2" (click)="clearDaata(1)" mat-stroked-button="">Clear
                        </button> -->
                      </form>
                    </mat-card-content>
                  </mat-card>
                </mat-menu>
                <button id="gbtn{{ sTableOptions.title }}" (click)="apiDownload('type')" color="primary" matSuffix
                  mat-icon-button *ngIf="tableOptions.apiDownload && tableOptions.pageTotal !== 0" matTooltip="Download"
                  aria-label="download xlsx menu">
                  <mat-icon class="mat-24">excelDownload</mat-icon>
                </button>
                <button id="gbtn{{ sTableOptions.title }}" (click)="downloadAsXls('full')" color="primary" matSuffix
                  mat-icon-button *ngIf="
                    !tableOptions.hideDownload
                  " matTooltip="Download" aria-label="download xlsx menu">
                  <mat-icon class="mat-24">excelDownload</mat-icon>
                </button>
                <mat-menu #xlsMenu="matMenu">
                  <button mat-menu-item id="currentSelection" *ngIf="tableOptions.filterDownload"
                    (click)="downloadAsXls('filtered')">
                    <mat-icon class="mat-24">filter_list</mat-icon>
                    <span>Filtered Data</span>
                  </button>
                  <button mat-menu-item id="fullData" (click)="downloadAsXls('full')">
                    <mat-icon class="mat-24">file_download</mat-icon>
                    <span>Full Data</span>
                  </button>
                </mat-menu>
                <button mat-icon-button *ngIf="tableOptions.compareData" matTooltip="Compare Data" color="primary"
                  (click)="CompareData(compSelection.selected)">
                  <mat-icon>compare</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Save Settings" id="save{{tableOptions.id}}" color="primary"
                  [matMenuTriggerFor]="saveMenu">
                  <mat-icon>save</mat-icon>
                </button>
                <mat-menu #saveMenu="matMenu">
                  <button mat-menu-item id="saveSettingT" (click)="SaveSettings()">
                    <mat-icon class="mat-24">save_alt</mat-icon>
                    <span>Save Table Settings</span>
                  </button>
                  <button mat-menu-item id="restSetting" (click)="ResetSettings()"
                    *ngIf="sessionData && sessionData.id">
                    <mat-icon class="mat-24">refresh</mat-icon>
                    <span>Reset Table Settings</span>
                  </button>
                </mat-menu>
                <button mat-raised-button color="primary" (click)="publish()" *ngIf="tableOptions.publish">
                  Publish
                </button>
                <a mat-icon-button matTooltip="Help Link" [href]="baseService.helpLinks[tableOptions.id]"
                  target="_blank" color="primary"
                  *ngIf="baseService.helpLinks && baseService.helpLinks[tableOptions.id] && baseService.helpLinks[tableOptions.id] !== ''">
                  <mat-icon>help</mat-icon>
                </a>
                <button *ngFor="let pbtn of tableOptions.parentCalls" mat-icon-button [matTooltip]="pbtn.tooltip"
                  [color]="pbtn.color" (click)="ParentCalls(pbtn.id)">
                  <mat-icon>{{pbtn.icon}}</mat-icon>
                </button>

                <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                  <div>
                    <button mat-button (click)="exportAllData()">
                      Export All Data
                    </button>
                  </div>
                </mat-menu>
              </form>
            </div>
          </div>

        </mat-toolbar>
      </div>

      <div class="block md:hidden">
        <mat-toolbar class="bg-card border-b w-full" role="heading" *ngIf="selection.selected.length === 0">
          <div class="flex flex-row justify-between px-2">
            <div class="flex flex-row items-center">
              <span class="text-3xl font-extrabold tracking-tight leading-none">
                {{ tableOptions.title }}
              </span>
              <button class="ml-2 px-3 lh-25 fuse-mat-button-small mini-form-field" mat-stroked-button *ngIf="tableOptions.add"
                type="button" matTooltip="Add New Record" color="primary" (click)="AddTableRecord()"
                id="addbtn{{ tableOptions.title }}">
                <mat-icon class="mat-18 mb-1 text-xl">add</mat-icon> Add
              </button>
            </div>
            <div class="flex flex-row mt-2 justify-between items-center">
              <form class="flex flex-row items-center justify-center">
                <mat-form-field floatLabel="never"
                  class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 mt-5"
                  *ngIf="tableOptions.search">
                  <input matInput class="form-control" [(ngModel)]="filterText" autocomplete="off" type="text"
                    trim="blur" name="filterText" [id]="tableOptions.id" (ngModelChange)="filterUpdate.next($event)"
                    placeholder="Search" />
                  <button class="gray" matSuffix mat-icon-button *ngIf="!filterText || filterText === ''"
                    color="primary">
                    <mat-icon>search</mat-icon>
                  </button>
                  <button class="gray" matSuffix mat-icon-button *ngIf="filterText && filterText !== ''"
                    (click)="filterText = ''; doFilter(filterText)" color="primary">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <div class="flex flex-row items-end" *ngIf="tableOptions.refreshData">
                  <button class="gray" matSuffix mat-icon-button matTooltip="Reload Table Data"
                    (click)="RefreshTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}" svgIcon="mat_outline:refresh"></mat-icon>
                  </button>
                </div>
                <div class="flex flex-row items-end" *ngIf="tableOptions.showFilter">
                  <button class="gray" matSuffix mat-icon-button matTooltip="Filter Table Data"
                    (click)="FilterTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}">filter_alt</mat-icon>
                  </button>
                </div>
                <div class="flex flex-row items-end" *ngIf="tableOptions.showTagFilter">
                  <button class="gray" matSuffix mat-icon-button matTooltip="Filter by Tags"
                    (click)="FilterTagTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}" svgIcon="heroicons_solid:tag"></mat-icon>
                  </button>
                </div>
                <button mat-icon-button menuOpen="false" color="primary" matTooltip="Filter List" *ngIf="
                    tableOptions.showColFilter &&
                    _sTableOptions.columns &&
                    _sTableOptions.columns.length
                  " [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                  <mat-icon>filter_list</mat-icon>
                </button>
                <button mat-icon-button menuOpen="false" (click)="openListColumns()" #listMenuTrigger="matMenuTrigger"
                  [matMenuTriggerFor]="belowMenu" color="primary" id="view{{tableOptions.id}}"
                  matTooltip="View Column, Reorder Columns" *ngIf="tableOptions.showhideList">
                  <mat-icon>view_column</mat-icon>
                </button>
                <mat-menu #belowMenu="matMenu" [hasBackdrop]="true" yPosition="below" class="p-4">
                  <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div class="flex flex-row items-center w-full" *ngFor="let item of showhideList; let i = index"
                      cdkDrag>
                      <i class="material-icons color_icon mr-1 mt-2 cursor-move"> drag_handle </i>
                      <mat-checkbox color="primary" [checked]="item.visible" (click)="$event.stopPropagation()"
                        (change)="showHideColumn(i, item)" class="left-align" [id]="item.header">
                        {{item.header}} </mat-checkbox>
                    </div>
                  </div>
                </mat-menu>
                <button mat-icon-button menuOpen="false" matTooltip="Filter List" *ngIf="sTableOptions.cFilter"
                  color="primary" [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                  <mat-icon class="">filter_list</mat-icon>
                </button>
                <mat-menu #filterMenu="matMenu" class="col-filters">
                  <mat-card class="filter-card" (click)="$event.stopPropagation();">
                    <mat-card-header>
                      <mat-card-title>Filter</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <form #filterForm="ngForm" role="form">
                        <mat-form-field *ngIf="sTableOptions.columns && sTableOptions.columns.length" class="w-full"
                          appearance="outline">
                          <mat-label>Choose filter column</mat-label>
                          <mat-select placeholder="Choose filter column" name="chooseFilter" required
                            [(ngModel)]="cFilter.selectedCol">
                            <mat-option value="">Choose filter column</mat-option>
                            <mat-option *ngFor="let val of sTableOptions.columns" [value]="val.col"
                              [id]="val.id">{{val.header}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="cFilter.selectedCol" class="w-full" appearance="outline">
                          <mat-label>
                            Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                          <input class="ml-3" matInput type="text" trim="blur"
                            *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                            placeholder="Search {{colHash[cFilter.selectedCol].header}}" required
                            name="{{colHash[cFilter.selectedCol].col}}s" [(ngModel)]="colHash[cFilter.selectedCol].val">
                          <mat-select placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                            name="{{colHash[cFilter.selectedCol].col}}s"
                            *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'" required
                            [(ngModel)]="colHash[cFilter.selectedCol].val">
                            <mat-option *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options"
                              [value]="val.value">{{val.name}}</mat-option>
                          </mat-select>
                          <ng-container *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'range'">
                            <mat-date-range-input [rangePicker]="picker">
                              <input class="ml-3" matStartDate name="startDate"
                                [(ngModel)]="colHash[cFilter.selectedCol].start" placeholder="Start date">
                              <input class="ml-3" matEndDate name="startDate"
                                [(ngModel)]="colHash[cFilter.selectedCol].end" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                          </ng-container>
                        </mat-form-field>
                        <button (click)="columnFilterxlsx(colHash[cFilter.selectedCol])"
                          [disabled]="!filterForm.form.valid" color="primary" mat-raised-button="">Add Filter
                        </button>
                        <!-- <button class="ml-2" (click)="cFilter.selectedCol = ''" mat-stroked-button="">Clear
                        </button> -->
                      </form>
                    </mat-card-content>
                  </mat-card>
                </mat-menu>
                <button id="gbtn{{ sTableOptions.title }}" (click)="apiDownload('type')" color="primary" matSuffix
                  mat-icon-button *ngIf="tableOptions.apiDownload && tableOptions.pageTotal !== 0" matTooltip="Download"
                  aria-label="download xlsx menu">
                  <svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <style>
                        .prefix__cls-1 {
                          fill: #0076c0;
                        }
                      </style>
                    </defs>
                    <g id="prefix__excel" transform="translate(0 -16.01)">
                      <g id="prefix__Group_699" data-name="Group 699" transform="translate(0 16.01)">
                        <g id="prefix__Group_698" data-name="Group 698">
                          <path id="prefix__Path_1049"
                            d="M12.131 16.193a.623.623 0 0 0-.565-.17l-11 2.4a.775.775 0 0 0-.566.786v17.6a.777.777 0 0 0 .561.786l11 2.4a.569.569 0 0 0 .127.014.632.632 0 0 0 .439-.184.858.858 0 0 0 .249-.616v-22.4a.855.855 0 0 0-.245-.616zM11 38.245l-9.629-2.1V19.873L11 17.774z"
                            class="prefix__cls-1" data-name="Path 1049" transform="translate(0 -16.01)" />
                        </g>
                      </g>
                      <g id="prefix__Group_701" data-name="Group 701" transform="translate(12.996 18.76)">
                        <g id="prefix__Group_700" data-name="Group 700">
                          <path id="prefix__Path_1050"
                            d="M266.316 79.99h-9.629a.858.858 0 0 0 0 1.682h8.941v15.135h-8.941a.858.858 0 0 0 0 1.682h9.629a.776.776 0 0 0 .688-.841V80.831a.776.776 0 0 0-.688-.841z"
                            class="prefix__cls-1" data-name="Path 1050" transform="translate(-256 -79.99)" />
                        </g>
                      </g>
                      <g id="prefix__Group_705" data-name="Group 705" transform="translate(12.23 21.884)">
                        <g id="prefix__Group_704" data-name="Group 704">
                          <path id="prefix__Path_1052"
                            d="M259.439 207.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                            class="prefix__cls-1" data-name="Path 1052" transform="translate(-256 -207.99)" />
                        </g>
                      </g>
                      <g id="prefix__Group_707" data-name="Group 707" transform="translate(12.23 26.584)">
                        <g id="prefix__Group_706" data-name="Group 706">
                          <path id="prefix__Path_1053"
                            d="M259.439 271.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                            class="prefix__cls-1" data-name="Path 1053" transform="translate(-256 -271.99)" />
                        </g>
                      </g>
                      <g id="prefix__Group_709" data-name="Group 709" transform="translate(12.23 31.284)">
                        <g id="prefix__Group_708" data-name="Group 708">
                          <path id="prefix__Path_1054"
                            d="M259.439 335.99h-2.751a.688.688 0 1 0 0 1.376h2.751a.688.688 0 0 0 0-1.376z"
                            class="prefix__cls-1" data-name="Path 1054" transform="translate(-256 -335.99)" />
                        </g>
                      </g>
                      <g id="prefix__Group_713" data-name="Group 713" transform="translate(18.498 21.884)">
                        <g id="prefix__Group_712" data-name="Group 712">
                          <path id="prefix__Path_1056"
                            d="M386.063 207.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                            class="prefix__cls-1" data-name="Path 1056" transform="translate(-384 -207.99)" />
                        </g>
                      </g>
                      <g id="prefix__Group_715" data-name="Group 715" transform="translate(18.498 26.584)">
                        <g id="prefix__Group_714" data-name="Group 714">
                          <path id="prefix__Path_1057"
                            d="M386.063 271.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                            class="prefix__cls-1" data-name="Path 1057" transform="translate(-384 -271.99)" />
                        </g>
                      </g>
                      <g id="prefix__Group_717" data-name="Group 717" transform="translate(18.498 31.284)">
                        <g id="prefix__Group_716" data-name="Group 716">
                          <path id="prefix__Path_1058"
                            d="M386.063 335.99h-1.375a.688.688 0 1 0 0 1.376h1.375a.688.688 0 1 0 0-1.376z"
                            class="prefix__cls-1" data-name="Path 1058" transform="translate(-384 -335.99)" />
                        </g>
                      </g>
                      <g id="prefix__Group_719" data-name="Group 719" transform="translate(3.872 24.57)">
                        <g id="prefix__Group_718" data-name="Group 718">
                          <path id="prefix__Path_1059"
                            d="M86.025 181.721l-4.814-5.5a.688.688 0 0 0-1.036.905l4.814 5.5a.688.688 0 0 0 1.036-.905z"
                            class="prefix__cls-1" data-name="Path 1059" transform="translate(-80.004 -175.984)" />
                        </g>
                      </g>
                      <g id="prefix__Group_721" data-name="Group 721" transform="translate(3.872 23.794)">
                        <g id="prefix__Group_720" data-name="Group 720">
                          <path id="prefix__Path_1060"
                            d="M85.924 160.138a.688.688 0 0 0-.966.12l-4.814 6.19a.688.688 0 0 0 1.085.846l4.814-6.19a.687.687 0 0 0-.119-.966z"
                            class="prefix__cls-1" data-name="Path 1060" transform="translate(-79.999 -159.992)" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
                <button id="gbtn{{ sTableOptions.title }}" (click)="downloadAsXls('full')" color="primary" matSuffix
                  mat-icon-button *ngIf="
                    !tableOptions.hideDownload && tableOptions.pageTotal !== 0
                  " matTooltip="Download" aria-label="download xlsx menu">
                  <mat-icon class="mat-24">excelDownload</mat-icon>
                </button>
                <mat-menu #xlsMenu="matMenu">
                  <button mat-menu-item id="currentSelection" *ngIf="tableOptions.filterDownload"
                    (click)="downloadAsXls('filtered')">
                    <mat-icon class="mat-24">filter_list</mat-icon>
                    <span>Filtered Data</span>
                  </button>
                  <button mat-menu-item id="fullData" (click)="downloadAsXls('full')">
                    <mat-icon class="mat-24">file_download</mat-icon>
                    <span>Full Data</span>
                  </button>
                </mat-menu>
                <button mat-icon-button *ngIf="tableOptions.compareData" matTooltip="Compare Data" color="primary"
                  (click)="CompareData(compSelection.selected)">
                  <mat-icon>compare</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Save Settings" id="save{{tableOptions.id}}" color="primary"
                  [matMenuTriggerFor]="saveMenu">
                  <mat-icon>save</mat-icon>
                </button>
                <mat-menu #saveMenu="matMenu">
                  <button mat-menu-item id="saveSettingT" (click)="SaveSettings()">
                    <mat-icon class="mat-24">save_alt</mat-icon>
                    <span>Save Table Settings</span>
                  </button>
                  <button mat-menu-item id="restSetting" (click)="ResetSettings()"
                    *ngIf="sessionData && sessionData.id">
                    <mat-icon class="mat-24">refresh</mat-icon>
                    <span>Reset Table Settings</span>
                  </button>
                </mat-menu>
                <button mat-raised-button color="primary" (click)="publish()" *ngIf="tableOptions.publish">
                  Publish
                </button>
                <a mat-icon-button matTooltip="Help Link" [href]="baseService.helpLinks[tableOptions.id]"
                  target="_blank" color="primary"
                  *ngIf="baseService.helpLinks && baseService.helpLinks[tableOptions.id] && baseService.helpLinks[tableOptions.id] !== ''">
                  <mat-icon>help</mat-icon>
                </a>
                <button *ngFor="let pbtn of tableOptions.parentCalls" mat-icon-button [matTooltip]="pbtn.tooltip"
                  [color]="pbtn.color" (click)="ParentCalls(pbtn.id)">
                  <mat-icon>{{pbtn.icon}}</mat-icon>
                </button>

                <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                  <div>
                    <button mat-button (click)="exportAllData()">
                      Export All Data
                    </button>
                  </div>
                </mat-menu>
              </form>
            </div>
          </div>
        </mat-toolbar>
      </div>

      <mat-toolbar class="select-tools" *ngIf="selection.selected.length > 0">
        <span>{{ selection.selected.length }}
          {{ tableOptions.selectText }} selected.</span>
        <div>
          <ng-container *ngIf="!tableOptions.isHideGlobal">
            <button mat-flat-button color="primary" id="gbtnSelction{{ tableOptions.title }}" mat-raised-button
              [matMenuTriggerFor]="gactionSMenu" [disabled]="selection.selected.length === 0"
              aria-label="global action with a menu">
              Global Actions
            </button>
            <mat-menu #gactionSMenu="matMenu">
              <ng-container *ngFor="let item of tableOptions.actionMenuItems">
                <a id="ganchsel{{ item.text }}" class="mat-primary" *ngIf="item.isGlobal" mat-menu-item
                  (click)="globalActionCall(selection.selected, item)">
                  <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
                  <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                  <span>{{ item.text }}</span>
                </a>
              </ng-container>
            </mat-menu>
          </ng-container>
          <button class="gray" matSuffix mat-icon-button (click)="clearSelection()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <!-- <mat-chip-list class="mb10">
        <mat-chip *ngFor="let filter of colFilters" (removed)="removeFilter(filter)">
          {{ filter.name }} = {{ filter.value }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list> -->
      <mat-chip-list *ngIf="colFilters.length > 0" class="mb10">
        <mat-chip *ngFor="let filter of colFilters" (removed)="removeFilter(filter)">
          {{ filter.name }} = {{ filter.value }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <mat-card-content [ngClass]="{ 'mt-2': colFilters.length }" class="mb-0">
        <div #MAINTABLEDIV class="overflow-100vh-auto" [style.max-height.px]="innerHeight">
          <table id="mytable" class="w-full text-nowrap" [ngClass]="{ tablePadding: isTablePadding === true }" mat-table
            [dataSource]="dataSource" *ngIf="dataSource" matSort #MainTable
            [matSortActive]="_sTableOptions['sortOptions']['active']"
            [matSortDirection]="_sTableOptions['sortOptions']['direction']" (matSortChange)="customSort($event)">
            <ng-container matColumnDef="select" *ngIf="tableOptions.rowSelection" sticky>
              <th mat-header-cell *matHeaderCellDef class="ww-break mytable-th">
                <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" id="_selectDeselectAll"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" class="ww-break">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()" [id]="row._id"
                  (change)="$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="compare" *ngIf="tableOptions.compareData">
              <th mat-header-cell *matHeaderCellDef class="ww-break mytable-th">
                Compare
              </th>
              <td mat-cell *matCellDef="let row" class="ww-break">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                  (change)="$event ? compSelection.toggle(row) : null" [checked]="compSelection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngFor="let column of _sTableOptions.columns" [matColumnDef]="column.columnDef">
              <th mat-header-cell *matHeaderCellDef class="text-slate-800 mytable-th"
                [style.max-width.px]="column.width">
                <mat-label mat-sort-header [disabled]="
                    column.isSort === undefined || column.isSort === null
                      ? 'false'
                      : !column.isSort
                  ">
                  <span>{{ column.header }}</span>
                </mat-label>
              </th>
              <td mat-cell [style.max-width.px]="column.width" *matCellDef="let row" [ngClass]="{
                  'text-center': column.align === 'center',
                  'text-left': !column.align || column.align === 'left',
                  'text-right': column.align === 'right',
                  'min-width':column.width
                }" class="wwbreak text-sm">
                <span *ngIf="column.filter">
                  <span *ngIf="column.isHyperlink && !column.isToolTip">
                    <ng-container *ngIf="
                        column.isTarget;
                        then thenTemplate;
                        else elseTemplate
                      "></ng-container>
                    <ng-template #thenTemplate>
                      <div innerHTML="{{
                          getProperty(row, column.columnDef)
                            | formatCell : column.filter
                        }}"></div>
                    </ng-template>
                    <ng-template #elseTemplate>
                      <a class="cursor-pointer font-bold text-primary border px-2 py-1 rounded"
                        (click)="hyperLinkCall(row, column.columnDef)" [id]="getProperty(row, column.columnDef)">
                        <span>{{
                          getProperty(row, column.columnDef)
                          | formatCell : column.filter
                          }}</span>
                      </a>
                    </ng-template>
                  </span>
                  <ng-container *ngIf="!column.isHyperlink && !column.isHtml">
                    <span *ngIf="column.isToolTip" matTooltipClass="example-tooltip-red"
                      matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                      {{
                      getProperty(row, column.columnDef)
                      | formatCell : column.filter
                      }}</span>
                    <span *ngIf="!column.isToolTip">{{
                      getProperty(row, column.columnDef)
                      | formatCell : column.filter
                      }}</span>
                  </ng-container>
                  <span *ngIf="!column.isHyperlink && column.isHtml">
                    <div [innerHTML]="
                        getProperty(row, column.columnDef) | safeHTML
                      "></div>
                  </span>
                </span>

                <ng-container *ngIf="column.hasMultiData">
                  <div *ngFor="let col of column.colList">
                    <span *ngIf="col.isHyperlink && !col.isToolTip">
                      <a class="pointer" (click)="hyperLinkCall(row, col.col)"
                        [id]="getProperty(row, column.columnDef)">
                        <span>{{ getProperty(row, col.col) }}</span>
                      </a>
                    </span>
                    <span *ngIf="col.isToolTip && !col.isHyperlink" matTooltipClass="example-tooltip-red"
                      matTooltip="{{ getProperty(row, col.isToolTipCol) }}">{{ getProperty(row, col.col) }}</span>
                    <span *ngIf="col.isHyperlink && col.isToolTip" matTooltipClass="example-tooltip-red"
                      matTooltip="{{ getProperty(row, col.isToolTipCol) }}">
                      <a class="pointer" [id]="getProperty(row, column.columnDef)"
                        (click)="hyperLinkCall(row, col.col)">
                        <span>{{ getProperty(row, col.col) }}</span>
                      </a>
                    </span>
                    <span *ngIf="col.isProgressCntrl">
                      <div class="mat-elevation-z2" [ngClass]="{
                          'progress-s':
                            getProperty(row, 'warn') >
                            getProperty(row, col.col),
                          'progress-d':
                            getProperty(row, 'warn') <=
                            getProperty(row, col.col)
                        }">
                        <span class="percent-b">{{ getProperty(row, col.col) }}%</span>
                        <div class="p-bar" [style.width]="getProperty(row, col.col) + '%'"></div>
                      </div>
                    </span>
                    <span *ngIf="col.isColoredCntrl">
                      <span *ngIf="getProperty(row, col.col) === '-'">{{
                        getProperty(row, col.col)
                        }}</span>
                      <span class="label" *ngIf="getProperty(row, col.col) !== '-'"
                        [ngStyle]="{ 'background-color': col.color }">{{ getProperty(row, col.col) }}</span>
                    </span>
                    <span *ngIf="col.isOfflineColoredCntrl">
                      <span *ngIf="getProperty(row, col.col) === '-'">{{
                        getProperty(row, col.col)
                        }}</span>
                      <span class="label1" *ngIf="getProperty(row, col.col) !== '-'"
                        [ngStyle]="{ 'background-color': col.color }">{{ getProperty(row, col.col) }}</span>
                    </span>
                    <span *ngIf="col.isfaicon">
                      <i class="{{ col.class }}" [ngStyle]="{ color: col.color }"></i>
                    </span>

                    <span *ngIf="col.filter">
                      {{ getProperty(row, col.col) | formatCell : col.filter }}
                    </span>

                    <span *ngIf="col.isAddingText">
                      {{ col.addingText }}{{ getProperty(row, col.col) }}
                    </span>

                    <span *ngIf="col.img && col.imgPath">
                      <img [src]="col.imgPath + 'generic.svg'" *ngIf="getProperty(row, col.col) === null" alt="generic"
                        class="{{ col.class }}" />
                      <img [src]="col.imgPath + getProperty(row, col.col)" *ngIf="getProperty(row, col.col) !== null"
                        alt="logo" class="{{ col.class }}" />
                    </span>

                    <span *ngIf="
                        !col.isHyperlink &&
                        !col.isProgressCntrl &&
                        !col.isColoredCntrl &&
                        !col.isOfflineColoredCntrl &&
                        !col.isToolTip &&
                        !col.filter &&
                        !col.isfaicon &&
                        !col.isAddingText &&
                        !col.img
                      ">
                      {{ getProperty(row, col.col) }}
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="column.isProgressCntrl">
                  <div class="mat-elevation-z2" [ngClass]="{
                      'progress-s':
                        getProperty(row, 'warn') >
                        getProperty(row, column.columnDef),
                      'progress-d':
                        getProperty(row, 'warn') <=
                        getProperty(row, column.columnDef)
                    }">
                    <span class="percent-b">{{ getProperty(row, column.columnDef) }}%</span>
                    <div class="p-bar" [style.width]="getProperty(row, column.columnDef) + '%'"></div>
                  </div>
                </ng-container>
                <ng-container *ngIf="column.isColoredCntrl">
                  <ng-container *ngIf="
                      column.isCustomText;
                      then thenTemplate;
                      else elseTemplate
                    "></ng-container>
                  <ng-template #thenTemplate>
                    <span class="label" *ngIf="getProperty(row, column.columnDef) !== '-'" [ngStyle]="
                        getCustomColor(getProperty(row, column.columnDef))
                      ">{{
                      getCustomText(getProperty(row, column.columnDef))
                      }}</span>
                  </ng-template>
                  <ng-template #elseTemplate>
                    <span *ngIf="getProperty(row, column.columnDef) === '-'">{{
                      getProperty(row, column.columnDef)
                      }}</span>
                    <span class="label" *ngIf="getProperty(row, column.columnDef) !== '-'"
                      [ngStyle]="{ 'background-color': column.color }">{{ getProperty(row, column.columnDef) }}</span>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="column.isOfflineColoredCntrl">
                  <ng-container *ngIf="
                      column.isCustomText;
                      then thenTemplate;
                      else elseTemplate
                    "></ng-container>
                  <ng-template #thenTemplate>
                    <span class="label1" *ngIf="getProperty(row, column.columnDef) !== '-'" [ngStyle]="
                        getCustomColor(getProperty(row, column.columnDef))
                      ">{{
                      getCustomText(getProperty(row, column.columnDef))
                      }}</span>
                  </ng-template>
                  <ng-template #elseTemplate>
                    <span *ngIf="getProperty(row, column.columnDef) === '-'">{{
                      getProperty(row, column.columnDef)
                      }}</span>
                    <span class="label1" *ngIf="getProperty(row, column.columnDef) !== '-'"
                      [ngStyle]="{ 'background-color': column.color }">{{ getProperty(row, column.columnDef) }}</span>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="column?.statusicon">
                  <img class="vendor-logo" [src]="getIconPath(row, column)" [matTooltip]="getIconTooltip(row, column)"
                    [alt]="getIconTooltip(row, column)" />
                  <div>
                    <span hidden [innerHTML]="getIconTooltip(row, column) | safeHTML"></span>
                  </div>
                </ng-container>

                <ng-container *ngIf="!column.filter">
                  <ng-container *ngIf="column.icon">
                    <ng-container>
                      <span>
                        <ng-container *ngFor="let icon of column.iconList">
                          <mat-icon [ngClass]="[icon.class]" *ngIf="
                              getProperty(row, column.columnDef) === icon.value
                            ">
                            {{ icon._icon }}
                          </mat-icon>
                        </ng-container>
                      </span>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngFor="let img of column.conditionList">
                    <img class="{{ img.class }}" [src]="img._img" [alt]="img.value"
                      *ngIf="getProperty(row, column.columnDef) === img.value" [matTooltip]="img.displaytext" />
                    <div>
                      <span hidden *ngIf="getProperty(row, column.columnDef) === img.value"
                        [innerHTML]="getProperty(row, column.columnDef)"></span>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="column.img && column.imgPath">
                    <img [src]="column.imgPath + 'generic.svg'" alt="generic" *ngIf="
                        getProperty(row, column.columnDef) === null ||
                        getProperty(row, column.columnDef) === undefined
                      " class="{{ column.class }}" />

                    <img [src]="
                        column.imgPath + getProperty(row, column.columnDef)
                      " [alt]="getProperty(row, column.columnDef)" title="{{
                        column.isToolTipCol === undefined
                          ? ''
                          : getProperty(row, column.isToolTipCol)
                      }}" *ngIf="
                        getProperty(row, column.columnDef) !== null &&
                        getProperty(row, column.columnDef) !== undefined
                      " class="{{ column.class }}" />
                    <div>
                      <span hidden [innerHTML]="getProperty(row, column.columnDef)"></span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column.isAddingText">
                    {{ column.addingText }}
                    {{ getProperty(row, column.columnDef) }}
                  </ng-container>

                  <span *ngIf="column.isHyperlink && !column.isToolTip">
                    <ng-container *ngIf="
                        column.isTarget;
                        then thenTemplate;
                        else elseTemplate
                      "></ng-container>
                    <ng-template #thenTemplate>
                      <div [innerHTML]="getProperty(row, column.columnDef)"></div>
                    </ng-template>
                    <ng-template #elseTemplate>
                      <a class="cursor-pointer font-bold text-primary border px-2 py-1 rounded"
                        (click)="hyperLinkCall(row, column.columnDef)" [id]="getProperty(row, column.columnDef)">
                        <span>{{ getProperty(row, column.columnDef) }}</span>
                      </a>
                    </ng-template>
                  </span>
                  <span *ngIf="column.isToolTip && !column.isHyperlink" matTooltipClass="example-tooltip-red"
                    matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                    {{ getProperty(row, column.columnDef) }}
                  </span>
                  <span *ngIf="column.isHyperlink && column.isToolTip" matTooltipClass="example-tooltip-red"
                    matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                    <a class="cursor-pointer font-bold text-primary border px-2 py-1 rounded"
                      (click)="hyperLinkCall(row, column.columnDef)" [id]="getProperty(row, column.columnDef)">
                      <span>{{ getProperty(row, column.columnDef) }}</span>
                    </a>
                  </span>
                  <span *ngIf="column.online" matTooltipClass="example-tooltip-red"
                    matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                    <span>{{ getProperty(row, column.columnDef) }}</span>
                  </span>
                  <span *ngIf="column.isfaicon">
                    <i class="{{ column.class }}" [ngStyle]="{ color: column.color }"></i>{{ getProperty(row,
                    column.columnDef) }}
                  </span>
                  <ng-container *ngIf="
                      !column.icon &&
                      !column.img &&
                      !column.isHyperlink &&
                      !column.isProgressCntrl &&
                      !column.isColoredCntrl &&
                      !column.isOfflineColoredCntrl &&
                      !column.hasMultiData &&
                      !column?.statusicon &&
                      !column.isfaicon &&
                      !column.isAddingText &&
                      !column.isToolTip
                    ">
                    {{ getProperty(row, column.columnDef) }}
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="action" *ngIf="tableOptions.showAction" stickyEnd>
              <th mat-header-cell *matHeaderCellDef class="wwbreak mytable-th dark:text-black" [ngClass]="
                  tableOptions.showAction === true ? 'color1' : 'color2'
                ">Action
                <mat-menu #gactionMenu="matMenu">
                  <ng-container *ngFor="let item of tableOptions.actionMenuItems">
                    <a id="ganch{{ item.text }}" *ngIf="item.isGlobal" mat-menu-item
                      (click)="globalActionCall(selection.selected, item)">
                      <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
                      <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                      <span [id]="item.text">{{ item.text }}</span>
                    </a>
                  </ng-container>
                </mat-menu>
              </th>
              <td mat-cell *matCellDef="let element" class="wwbreak bg-white dark:bg-card" [ngClass]="
                  tableOptions.showAction === true ? 'color1' : 'color2'
                ">
                <button id="btn{{ tableOptions.id }}" mat-icon-button color="primary" [matMenuTriggerFor]="actionMenu"
                  aria-label="row action with a menu" [disabled]="selection.selected.length > 0">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #actionMenu="matMenu">
                  <ng-container *ngIf="!tableOptions.isActionCondition">
                    <ng-container *ngFor="let item of tableOptions.actionMenuItems">
                      <a id="anch{{ item.text }}" *ngIf="item.callback && !item.hideLocal" mat-menu-item
                        (click)="actionCall(element, item)">
                        <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
                        <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                        <span [id]="item.text">{{ item.text }}</span>
                      </a>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="
                      tableOptions.isActionCondition &&
                      tableOptions.actionConditons &&
                      tableOptions.actionConditons.key
                    ">
                    <ng-container *ngFor="
                        let item of tableOptions.actionConditionsList[
                          element[tableOptions.actionConditons.key]
                        ]
                      ">
                      <a id="anch{{ item.text }}" *ngIf="item.callback && !item.hideLocal" mat-menu-item
                        (click)="actionCall(element, item)">
                        <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
                        <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                        <span [id]="item.text">{{ item.text }}</span>
                      </a>
                    </ng-container>
                  </ng-container>
                </mat-menu>
              </td>
            </ng-container>

            <tr class="overflow-hidden" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

            <tr class="overflow-hidden" mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
              [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (click)="rowClick(row, i)"
              (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
          </table>

          <div class="ml-2" *ngIf="!dataSource.data || dataSource.data.length === 0">
            <span *ngIf="tableOptions.loading">Please wait!</span>
            <div class="flex flex-col items-center spaces-y-4" *ngIf="
                !tableOptions.loading &&
                tableOptions.pageTotal === 0 &&
                !tableOptions.nomsg
              ">
              <span class="pb-6 pt-6 text-secondary">No Records Found. </span>
            </div>
            <span *ngIf="
                !tableOptions.loading &&
                tableOptions.pageTotal === 0 &&
                tableOptions.nomsg
              ">
              {{ tableOptions.nomsg }}
            </span>
          </div>
        </div>
        <mat-paginator class="mr20 t-paginator" *ngIf="tableOptions.pagination && tableOptions.pageTotal < 10000"
          [pageSize]="tableOptions.pageSize" showFirstLastButtons [length]="tableOptions.pageTotal" #paginator
          [pageSizeOptions]="tableOptions.pageOptions" (page)="pageChanged($event)"></mat-paginator>
        <mat-paginator class="mr20 t-paginator" *ngIf="tableOptions.pagination && tableOptions.pageTotal > 9999"
          [pageSize]="tableOptions.pageSize" [length]="tableOptions.pageTotal" #paginator
          [pageSizeOptions]="tableOptions.pageOptions" (page)="pageChanged($event)"></mat-paginator>
      </mat-card-content>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-card>
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { ModalService } from 'app/_services/modal.service';
import { MyToastrService } from 'app/_services/toastr.service';

@Component({
  selector: 'app-massflow',
  templateUrl: './massflow.component.html',
  styleUrls: ['./massflow.component.scss']
})
export class MassflowComponent {
  postData: any = [];
  massdata: any = []
  massInterval: number = 2;
  massInterval1: number = 2;
  massInterval2: number = 2;
  massInterval3: number = 2;
  // @Input() tank;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public matDialogRef: MatDialogRef<MassflowComponent>,
    private _formBuilder: UntypedFormBuilder,
    public modalService: ModalService,
    private api: BaseRequestService,
    public toast: MyToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseConfirmationService: FuseConfirmationService,
  ) {
  }
  ngOnInit(): void {
    this.postData = this.data;
    this.massdata = this.data;
    console.log('massdata', this.massdata);
    this.getMFMData();
  }
  getMFMData() {
    let q = {
      skip: 0, limit: 10, q: {
        "query": {
          "bool": {
            "must": [{ "match": { "_type_.keyword": "mfm_masterdata" } },
            { "match": { "ro_master_ref.ro_code.keyword": this.massdata[0].ro_master_ref.ro_code } }]
          }
        }
      }
    }
    this.api.doRequest(`api/mfm_masterdata`, 'get', null, q).subscribe((result: any) => {
      console.log('result', result);
      if (result && result.data && result.data.length > 0) {
        if (result.data && result.data.length > 1) {
          result.data.forEach((data) => {
            if (data.meter_id ==1) {
              this.massInterval1 = (data.time_interval) ? data.time_interval : this.massInterval1
            } else if (data.meter_id == 2) {
              this.massInterval2 = (data.time_interval) ? data.time_interval : this.massInterval2
            }
            else if (data.meter_id == 3) {
              this.massInterval3 = (data.time_interval) ? data.time_interval : this.massInterval3
            }
          })

        } else if (result.data && result.data.length === 1) {
          result.data.forEach((data) => {
            if (data.meter_id == 1) {
              this.massInterval = (data.time_interval) ? data.time_interval : this.massInterval
            }
          })
        }

      }
    })
  }
  Close() {
    this.matDialogRef.close();
  }
  submitTotalInterval() {
    var dataValue = []
    if (this.massdata && this.massdata.length > 1) {
      dataValue = [
        {
          time_interval: this.massInterval1, ro_code: this.massdata[0].ro_master_ref.ro_code, meter_id: '1'
        },
        {
          time_interval: this.massInterval2, ro_code: this.massdata[1].ro_master_ref.ro_code, meter_id: '2'
        },
        {
          time_interval: this.massInterval3, ro_code: this.massdata[2].ro_master_ref.ro_code, meter_id: '3'
        }
      ];
    } else {
      dataValue = [
        {
          time_interval: this.massInterval, ro_code: this.massdata[0].ro_master_ref.ro_code, meter_id: '1'
        },
      ];
    }
    console.log('dataValue', dataValue);
    this.api.doRequest(`api/mfmeter_masterdata/set_mfm_interval`, 'post', dataValue).subscribe((result: any) => {
      if (result && result.status == 'success') {
        this.matDialogRef.close();
        this.toast.sToast('success', `Mass Flow Meter Interval Updated Sucessfully`)
      }
      else {
        if (result && result.msg) {
          this.toast.sToast("error", result.msg)
        }
        else {
          this.toast.sToast("error", "Something went wrong please try again!..")
        }
      }
    })
  }
}
